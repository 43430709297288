.table {
	margin-bottom: 0.75rem;
	thead {
		background-color: $table_thead_bg;
		tr {
			th {
				text-align: center;
				text-transform: uppercase;
				// text-align: center;
				vertical-align: middle !important;
				// border-right: solid 1px #7d7d7d;
                font-weight: 500; font-size: .95rem;
				background: #80808012;
				&:first-child {
					border-top-left-radius: 8px;
				}
				&:last-child {
					border-top-right-radius: 8px;
					border-right: 0px;
				}
			}
		}

		th,
		td {
			padding: 0.3rem 0.5rem;
			color: $white;
			border: none;
			border-right: solid 1px #e5e5e5;
			font-weight: 400;
			vertical-align: middle;
		}
	}
	tbody {
		tr {
			&:hover {
				// background: #f4f4f4;
			}
		}
		td {
			padding: 0.25rem 0.5rem 0.2rem;
			border: solid 1px #e5e5e5;
			vertical-align: middle;
			position: relative;
			border-top: 0;
			&:last-child {
				border-right: 0px;
				&:after {
					border-right: solid 1px #e5e5e5;
					content: "";
					position: absolute;
					height: 100%;
					right: 0px;
					top: 0;
				}
			}
			&.action {
				a {
					text-decoration: none;
					outline: none;
					display: flex;
					justify-content: center;
					align-self: center;
				}
				a:not(:last-child) {
					margin-right: 15px !important;
				}
			}

			&.columnborder {
				&::after {
					content: "";
					display: block;
					width: 0.025rem;
					height: 100%;
					position: absolute;
					top: 0px;
					z-index: 9;
					background: #ccc;
					left: var(--from-left);
				}
			}
		}
	}
	tfoot {
		border: solid 1px #e5e5e5;
		border-right: 0px;

		td {
			padding: 0.25rem 0.5rem 0.2rem;
			border-left: solid 1px #e5e5e5;
		}

		&.bg-light-gray {
			td.border-left {
				border-left: solid 1px rgba(0, 0, 0, 0.15) !important;
			}
		}
	}
	tr.itmemove {
		a.arrow_up,
		a.arrow_down {
			color: $default;
			text-decoration: none;
		}
		&:first-child a.arrow_up {
			visibility: hidden;
		}
		&:last-child a.arrow_down {
			visibility: hidden;
		}
	}

	&.rounded-0 {
		thead tr:first-child th:first-child,
		thead tr:first-child td:first-child {
			border-top-left-radius: 0rem !important;
		}
		thead tr:first-child th:last-child,
		thead tr:first-child td:last-child {
			border-top-right-radius: 0rem !important;
		}
		td {
			position: relative;
			&:last-child {
				border-right: 0px;
				&:after {
					border-right: solid 1px #e5e5e5;
					content: "";
					position: absolute;
					height: 100%;
					right: 0px;
					top: 0;
				}
			}
		}
	}

	&.table-borderless {
		tbody {
			td {
				border-color: transparent;
			}
		}
	}

	.active-table {
		background: #f4f4f4;
	}
}

/*
tbody {
    display:block;
    height:250px;
    overflow-y:auto;
}
thead, tbody tr, tfoot {
    display:table;
    width:100%;
    table-layout:fixed;
}
*/

.table-transparent {
	tbody {
		td,
		td:last-child:after {
			border-width: 0 !important;
		}
		tr {
			&:hover {
				background: inherit !important;
			}
		}
	}
}

.position-sticky {
	th {
		position: sticky !important;
		top: 0px;
		background: #7d7d7d !important;
		z-index: 9;
		border-right: solid 1px #e5e5e5 !important;
	}
}

.position-sticky2 {
	th {
		position: sticky !important;
		top: 0px;
		z-index: 9;
		border-right: solid 1px #e5e5e5 !important;
	}
}

table {
	&.simple {
		th {
			font-size: 16px;
			font-weight: 500;
			color: #333;
		}
		td {
			font-size: 14px;
			color: #555;
		}
	}
}
.print {
	table {
		font-family: Roboto, sans-serif;
		th {
			font-size: 16px;
			font-weight: 500;
			color: #333;
		}
		td {
			font-size: 14px;
			color: #555;
		}
		tbody,
		td,
		th {
			border-collapse: collapse !important;
			border: 1px solid #333;
			padding: 5px !important;
		}
	}
}


.rx-badge {
	width: 30px;
	flex: 0 0 30px;
	height: 15px;
	display: inline-block;
	text-align: center;
	border-radius: 50px;
	color: white;
	font-size: 11px;
	font-weight: 500;
	margin-right: 10px;
	line-height: 16px;
	// background: #61bd4f;
	
}

.row-divn{
	// margin-top: -9px;
    margin-bottom: -28px;
}
.row-hyt{
	margin-top: -17px;
	margin-bottom: -8px;
}
.row-widt{
	margin-top: -7px;
	margin-bottom: -10px;
}
.row-wids{
margin-top: -8px;
margin-bottom: -10px;
}
.row-nam{
	margin-bottom: -11px;
	margin-top: -12px;
}
.row-innt{
	margin-bottom: -11px;
}

.card-hyt{
	margin-top: -16px;
}
.text-areahyt{
	height: 36px;
}
.row-witdh{
	margin-bottom: -14px;
}
.cols-hyt{
	margin-top: -22px;
}

.table-td-hover td:hover{background: #f5f5f5!important;}
.table-td-hover td:first-child:hover{background: #fff!important;}


.trhover tbody tr:hover{background:#f5f5f5!important;}

.custom__initiateNursingTable {
	.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
		background-color: #fff !important;
	}
}

.table-containerr{

table {
	border-collapse: collapse; 
	font-family: helvetica;
	caption-side: top;
	text-transform: capitalize;
	width:100%;
  }
  
  td, th {
		padding:3px 10px;
		min-width: 200px;
		background: white;
		box-sizing: border-box;
		text-align: left;
  }
  
  th {
	box-shadow: 0 0 0 1px black;
  }
  .table-container {
	position: relative;
	max-height:  300px;
	width: 500px;
	overflow: scroll;
  }
  
  thead th, 
  tfoot th {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 2;
	background: hsl(20, 50%, 70%);
  }
  
  thead th:first-child,
  tfoot th:first-child {
	left: 0;
	z-index: 3;
  }
  
  tfoot {
	position: -webkit-sticky;
	bottom: 0;
	z-index: 2;
  }
  
  tfoot th {
	position: sticky;
	bottom: 0;
	z-index: 2;
	background: hsl(20, 50%, 70%);
  }
  
  tfoot td:first-child {
	z-index: 3;
  }
  

  
  /* MAKE LEFT COLUMN FIXEZ */
  tr  td:first-child {
	position: -webkit-sticky;
	position: sticky; 
	background:#f4f4f4;
	left: 0; 
	z-index: 1;
  }
 
  tr  td:last-child {
	position: -webkit-sticky;
	position: sticky; 
	background:#f4f4f4;
	right: 0; 
	z-index: 1;
  }
  
  tr  td:nth-last-child(2) {
	position: -webkit-sticky;
	position: sticky; 
	background:#f4f4f4;
	right: 200px; 
	z-index: 1;
  }
  
}





.table-top-left-sticky{

	.table-top-left-sticky > table {
		border-collapse: collapse; 
		font-family: helvetica;
		caption-side: top;
		text-transform: capitalize;
		width:100%;
	  }
	  
	  td, th {
			padding:3px 10px;
			min-width: 200px;
			background: white;
			box-sizing: border-box;
			text-align: left;
	  }
	  
	  th {
		box-shadow: 0 0 0 1px black!important;
	  }
	  .table-container {
		position: relative;
		max-height:  300px;
		width: 500px;
		overflow: scroll;
	  }
	  
	  thead th, 
	  tfoot th {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 2;
		background: hsl(20, 50%, 70%);
	  }
	  
	  thead th:first-child,
	  tfoot th:first-child {
		left: 0;
		z-index: 3;
	  }
	  
	  tfoot {
		position: -webkit-sticky;
		bottom: 0;
		z-index: 2;
	  }
	  
	  tfoot th {
		position: sticky;
		bottom: 0;
		z-index: 2;
		background: hsl(20, 50%, 70%);
	  }
	  
	  tfoot td:first-child {
		z-index: 3;
	  }
	  
	
	  
	  /* MAKE LEFT COLUMN FIXEZ */
	  tr  td:first-child {
		position: -webkit-sticky;
		position: sticky; 
		left: 0; 
		z-index: 1;
	  }
	 

	  
	}


	.tdfirstchild {
		position: -webkit-sticky;
		position: sticky!important; 
		left: 0; 
		z-index: 1;
		background: #eee!important;		
		color: #000 !important;
	  }
	  .thfirstchild{
		position: sticky!important;
		bottom: 0;
		z-index: 2;
		background: #eee!important;
	  }

	  .row-bg{
		background: #aaa3a330;
	  }
	  .row-bgdark{
		background: #0000007a;
		color: white;
	  }
	  .advise-ul{
		font-weight: 500;
		font-size: 16px;
	  }
	  .modal-size{
		  width: 900px;
	  }
	  .small-lable{
		  font-size: 14px;
	  }
	  .btn-align{
		  float: right;
	  }
	  .align-right{
		  display: contents;
	  }


	  .table-colon{
		padding-left:8px;

		  position: relative;
		  &:before{
		  position: absolute;
		  content:':';
		  left:0px;
	}
	  }