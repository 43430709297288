.table {
    color: $color_text_default;

    thead {
        background-color: $color_table_header;
        font-size: .875rem;
        text-transform: uppercase;
        color: $color_white;

        th,
        td {
            border-color: rgba(0, 0, 0, 0.1);
            font-weight: 500;
        }
    }

    tbody {
        tr {
            td {
                vertical-align: middle;
                border-color: rgba(0, 0, 0, 0.1);

                &.action {
                    width: 130px;
                    text-align: left;

                    a:first-child {
                        margin-left: 5px;
                    }

                    a:not(:last-child) {
                        margin-right: 15px !important;
                    }

                    img {
                        max-width: 26px;
                    }
                }

                &.image-reference-column {
                    width: 170px;

                    .image-references {
                        .image-item {
                            width: 36px;
                            text-align: center;

                            a {
                                color: $black;
                            }

                            img {
                                border: solid 4px $color_gray_border;
                            }
                        }
                    }
                }

                .table-form-control {
                    border: none;
                    background: transparent;
                    outline: none;
                    width: 100%;
                }

                &.due-now {
                    background: #d6eaff;
                }

                &.missed {
                    background: #ffcccc;
                }
            }
        }
    }


    &.table-card {
        border: solid 1px #dee2e6;
        word-break: break-all;
        th,
        td {
            padding: 0.4rem 0.5rem;
        }

        thead {
            background: $bg_alice_blue;
        }
    }

    &.table-noborder {
        td {
            border-top: none;
        }
    }

    &.rounded {
        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 0.25rem;
                }

                td:last-child {
                    border-bottom-right-radius: 0.25rem;
                }
            }
        }
    }


}

.table-default {
    background-color: #fff;
    margin-bottom: 0px;

    thead {

        th {
            border-style: solid;
            border-width: 1px;
            border-color: $color_table_header rgba(0, 0, 0, 0.1) $color_table_header rgba(0, 0, 0, 0.1);
            background-color: $color_table_header;
            text-transform: uppercase;
            color: $color_white;
            vertical-align: top;
            padding: 0.6rem 0.5rem;
            font-weight: 400;
        }

        tr th[valign="middle"] {
            vertical-align: middle;
        }
    }

    tbody {
        tr td {
            padding: 0.5rem;
            color: $color_text_default;
            border: solid 1px rgba(0, 0, 0, 0.1);
            vertical-align: top;

            &.cardbg {
                padding: 1rem;
            }
        }

        tr td[valign="middle"] {
            vertical-align: middle;
        }

        tr:hover {
            background-color: $color_smoke_gray;
        }

        tr:nth-child(even),
        tr.even {
            background: $color_alice_blue;
        }

        tr.odd {
            background: $color_white;
        }
    }

}

.table-label {
    tr {
        td {
            background: $default;
            color: $color_white;
            font-weight: 300;

            strong {
                font-weight: 500;
            }
        }
    }
}

.table-chart {
    border: solid 1px rgba(0, 0, 0, 0.1);

    thead td {
        border-color: #fff;
    }

    tbody td {
        font-weight: 500;
    }

    dl {
        display: block;
        width: 100%;
        margin: 0;
        float: left;

        dt {
            display: inline-block;
            width: 38%;
            font-weight: 300;
            margin: 0px;
        }

        dd {
            display: inline-block;
            width: 60%;
            font-weight: 500;
            margin: 0px;
            position: relative;

            &:before {
                content: ":";
                display: inline-block;
                margin-right: 5px;
            }
        }
    }


}

.table-border {

    th,
    td {
        border: solid 1px rgba(0, 0, 0, .1);
    }
}

.view-active {
    background-color: #d3d1d1 !important;

    td {
        color: #000 !important;
    }
}

.view_details {
    td {
        padding: 1.5rem 1.25rem 1.25rem !important;

        table {
            thead th {
                background-color: $color_alice_blue;
                color: $black;
                border-color: rgba(0, 0, 0, .1) !important;
                font-weight: 500;
            }

            tbody {
                tr {
                    background-color: #fff !important;
                }

                td {
                    padding: 0.6rem !important;
                }
            }
        }
    }
}

.patient-vital-table-container {
    table {
        tr {
            th {
                vertical-align: middle;
                text-transform: none;
                height: 40px;
                padding-top: 0;
                padding-bottom: 0;
            }

            &.second-throw {
                th {
                    padding-top: 0;
                    padding-bottom: 0;
                    height: unset;
                }
            }
        }
    }
}