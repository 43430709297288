.my-calendar {

  // * {
  //   box-sizing: content-box;
  // }

  .fc-sun {
    background-color: #f0f0f0;
  }

  .fc-toolbar.fc-header-toolbar {

    .fc-left,
    .fc-right {
      width: 50px;
      height: 28px;
    }

    // .fc-left{visibility:hidden;}

    background-color: $lightgrey;
    padding: 5px 0;
    margin: 0px;

    .fc-button {
      background: none;
      border: none;
      box-shadow: none;
      outline: none;
      margin: 0px;
      padding: 0px;

      .fc-icon-left-single-arrow {
        width: 50px;
        height: 40px;
        display: block;
        margin: 0px;
        padding: 0px;

        &:after {
          content: '';
          width: 15px;
          height: 15px;
          border: solid 0.075rem #999;
          display: inline-block;
          transform: rotate(-45deg);
          top: 7px;
          border-right-color: transparent;
          border-bottom-color: transparent;
        }

        &:hover {
          &:after {
            border-left-color: #333;
            border-top-color: #333
          }
        }
      }

      .fc-icon-right-single-arrow {
        width: 50px;
        height: 40px;
        display: block;
        margin: 0px;
        padding: 0px;

        &:after {
          content: '';
          width: 15px;
          height: 15px;
          border: solid 0.075rem #999;
          display: inline-block;
          transform: rotate(-45deg);
          top: 7px;
          border-left-color: transparent;
          border-top-color: transparent;
        }

        &:hover {
          &:after {
            border-right-color: #333;
            border-bottom-color: #333
          }
        }
      }
    }

    h2 {
      font-size: 20px;
      font-weight: 400;
      line-height: 1;
      padding-top: 2px;
      color: #333;
    }

  }

  table thead,
  table thead th,
  table tbody td,
  .fc-widget-header {
    border: none;
    font-weight: 300;
    text-align: center;
  }

  .fc-ltr .fc-dayGrid-view .fc-day-top.fc-today .fc-day-number {
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: transparent;
      position: absolute;
      left: 0px;
      top: 85%;
      z-index: 999;
    }
  }

  .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
    float: unset;
    text-align: center;
    display: block
  }

  .fc-event {
    display: inline-block;
    background: none;
    border: none;
    border-top: solid 1px #5caf80;
    border-radius: 0;
    padding: 5px 10px;
    cursor: pointer;

    .fc-title {
      color: $primary;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .fc-day-number {
    cursor: pointer;
  }

  .fc-today {
    border: none !important;
    background: none !important;

    .fc-day-number {
      background: $primary !important;
      color: #fff !important;
    }
  }

  .fc-widget-header {
    background-color: $color_text_default;
    color: $white;

    span {
      padding: 7px 0;
      display: inline-block;
    }
  }

  .fc-dayGrid-view .fc-body .fc-row {
    max-height: 60px;
    border: solid 1px #f0f0f0;
    padding: 10px 0px;
    border-top: none;
    background: #fcfcfc;
  }

  .fc-body table thead td span {
    display: inline-block !important;
    padding: 5px 10px;
    border-radius: 0px; // 8px;
    font-size: 18px;
    min-width: 50px;

    &.booked {
      border-radius: 0px; //8px 8px 0px 0px;
    }

    &.active {
      background: #ccc;
      color: $primary;
    }
  }

  .fc-body table tbody td {
    a {
      display: inline-block !important;
      padding: 5px 10px;
      border-radius: 0px; //0 0 8px 8px;
      margin: -1px 0 0 !important;
      position: relative;
      min-width: 50px;

      &.fc-event {
        border-top: none !important;
        pointer-events: none;

        &:before {
          content: '';
          display: block;
          position: absolute;
          pointer-events: none;
          width: 100%;
          height: 100%;
          left: 0px;
          bottom: 100%;
        }
      }

      &:after {
        content: '';
        display: block;
        width: 60%;
        height: 1px;
        left: 20%;
        top: 0;
        position: absolute;
        background: #ccc
      }

      &.active {
        background: #ccc;

        &:after {
          background: #869eb6
        }
      }

      &.emr-today {
        background-color: $primary;

        div {

          background: $primary;

          .fc-title {
            color: #fff !important;
            font-size: 15px;
          }

          &:after {
            background: #fff
          }

          &:before {
            height: 0px !important;
          }
        }
      }
    }
  }
}


.my-calendar-accordion {
  .card {
    border: none;
    margin-bottom: 15px;

    .card-header {
      border-bottom: none;

      h6 {
        color: #333;
        position: relative;
        cursor: pointer;

        span {
          margin-top: 0.25rem
        }

        strong {
          position: absolute;
          right: 40px;
          top: 12px;
          font-size: 18px;
          color: $primary;
        }

        &:after {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          position: absolute;
          top: 18px;
          right: 0px;
          z-index: 99;
          border: solid 3px #999;
          border-left-color: transparent;
          border-top-color: transparent;
          transform: rotate(-135deg);
        }

        &.collapsed {
          &:after {
            transform: rotate(45deg);
            top: 8px
          }

        }

      }
    }

    .card-body {
      padding: 0;
      border-left: solid 1px rgba(0, 0, 0, .06);
      border-bottom: solid 1px rgba(0, 0, 0, .06);
      max-height: 448px;
      overflow-y: auto;

      .table {
        margin: 0;

        td,
        th {
          white-space: nowrap;
        }
      }
    }
  }

  ul.slots {
    margin: 0px;
    padding: 0.35rem 0 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    .list-inline-item {
      margin: 0.35rem 1%;
      max-width: 23%;
    }
  }

  ul.slots_color {
    margin: 0px;
    padding: 0.5rem 0.5rem 0;
    display: block;

    li {
      display: inline-block;
      margin-right: 1rem;

      i {
        width: 0.7rem;
        height: 0.7rem;
        background: #f00;
        display: inline-block;

        &.available {
          background: $slotAvailable;
        }

        &.booked {
          background: $slotBooked;
        }

        &.blocked {
          background: $slotBlocked;
        }

        &.lapsed {
          background: $slotLapsed;
        }
      }
    }

    &:after {
      content: '';
      display: block;
      width: 60%;
      height: 1px;
      left: 20%;
      top: 0;
      position: absolute;
      background: #ccc
    }

    &.active {
      background: #ccc;

      &:after {
        background: #869eb6
      }
    }
  }

  &.emr-today a {
    background: $primary;

    .fc-title {
      color: #fff !important;
      font-size: 15px;
    }

    &:after {
      background: #fff
    }

    &:before {
      height: 0px !important;
    }
  }
}


.my-calendar-accordion {
  .card {
    border: none;
    margin-bottom: 15px;

    .card-header {
      border-bottom: none;

      h6 {
        color: #333;
        position: relative;
        cursor: pointer;

        span {
          margin-top: 0.25rem
        }

        strong {
          position: absolute;
          right: 40px;
          top: 12px;
          font-size: 18px;
          color: $primary;
        }

        &:after {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          position: absolute;
          top: 18px;
          right: 0px;
          z-index: 99;
          border: solid 3px #999;
          border-left-color: transparent;
          border-top-color: transparent;
          transform: rotate(-135deg);
        }

        &.collapsed {
          &:after {
            transform: rotate(45deg);
            top: 8px
          }

        }

      }
    }

    .card-body {
      padding: 0;
      border-left: solid 1px rgba(0, 0, 0, .06);
      border-bottom: solid 1px rgba(0, 0, 0, .06);
      max-height: 448px;
      overflow-y: auto;

      .table {
        margin: 0;

        td,
        th {
          white-space: nowrap;
        }
      }
    }
  }


  ul.slots_color {
    margin: 0px;
    padding: 0.5rem 0.5rem 0;
    display: block;

    li {
      display: inline-block;
      margin-right: 1rem;

      i {
        width: 0.7rem;
        height: 0.7rem;
        background: #f00;
        display: inline-block;

        &.available {
          background: $slotAvailable;
        }

        &.booked {
          background: $slotBooked;
        }

        &.blocked {
          background: $slotBlocked;
        }

        &.lapsed {
          background: $slotLapsed;
        }
      }
    }

  }
}

.mycalendar-tabs {
  list-style: none;
  border: solid 2px $primary;
  padding: 0;
  margin: 0 auto;
  border-radius: 8px;
  background: #fff;
  display: -webkit-flex;
  -webkit-justify-content: center;
  display: flex;
  justify-content: center;

  li {
    display: table-cell;
    border-right: solid 2px $primary;
    -webkit-flex: 1;
    flex: 1;
    text-align: center;

    .nav-link.active {
      background-color: $primary;
      border-radius: 0;
      margin: -1px -2px -1px -1px !important;
      border-bottom: solid 2px $primary;
    }

    &:first-child .nav-link.active {
      border-radius: 0.25rem 0 0 0.25rem;
    }

    &:last-child {
      border-right: none;

      .nav-link {
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }
  }
}

.fc-button .fc-icon {
  color: #5c5c5c;
}

.react_datepicker {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  float: left;
  width: 100%;
  padding: 0.45rem;

  .react-datepicker-wrapper {
    float: left;
    width: calc(100% - 35px);

    input {
      border: none;
      width: 100%;
      outline: none;
    }
  }

  .react-datepicker__tab-loop {
    position: relative;
    z-index: 9999;
  }
}

.emrfrm .form-group.react_datepicker.hasdata>label {
  top: -12px;
}

.emrfrm .form-group.react_datepicker {
  padding-right: 0px;
  min-height: 44px;

  .input-group-addon {
    border: none;
    background: transparent;
  }

  &.disabled {
    background-color: #fff; // #f1f1f1;

    input {
      background: transparent;
    }

    label {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, white 50%, white 100%);
    }
  }
}

.fc-highlight {
  background-color: transparent !important;
}

.is-active .fc-day-number,
.is-active a {
  background: #5c5c5c !important;
  color: #fff !important;

  .fc-title {
    color: #fff !important;
  }
}

td.fc-highlight:after {
  content: "";
  width: 65px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 65px;
  background: #5c5c5c;
}

td.fc-highlight {
  position: relative;
}