.ant-input-select {
    input[type="text"] {
        // height: 44px;
        // border-radius: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
    }
    .ant-select-selector {
        background-color: #fff !important;
        color: $black;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

.ant-btn-primary {
    background-color: $primary;
    border-color: $primary-border;
    border-radius: 0.25rem;
    &:hover,
    &:focus {
        background-color: $primary-border;
        border-color: $primary-border;
    }
}

.ant-btn-default {
    border-color: $default;
    border-radius: 0.25rem;
    &:hover,
    &:focus {
        border-color: $primary;
        color: $primary;
    }
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: $danger;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    line-height: 1;
    content: "*";
}

.ant-form-item {
    margin-bottom: 0rem !important;
}

.ant-form label {
    font-size: 1rem;
    color: #5c5c5c;
}

.ant_switch_button_sm {
    .btn {
        font-size: 1rem !important;
        line-height: 1.25 !important;
        padding: 0 0.4rem !important;
        border-radius: 0 !important;
    }
    .switch-handle {
        background-color: $white;
    }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.25rem;
}

.ant-select {
    .ant-select-arrow {
        font-size: 16px;
        top: 50%;
    }
    &.ant-select-open {
        .ant-select-arrow {
            font-size: 22px;
            top: 50%;
            right: 10px;
        }
    }
    &.ant-select.ant-select-disabled {
        .ant-select-selector {
            background-color: #fff!important;
        }
    }
}

.ant-input-disabled {
    background: white!important;
    border-radius: 4px!important;
    color: $default!important;
}

.emrfrm .form-group.hasdata .ant-select.ant-select-disabled~label {
    // background: -moz-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
    // background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
    // background: -o-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
    // background: -ms-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgb(255, 254, 254) 50%, rgb(255, 255, 255) 100%);
}

.emrfrm .form-label-group {
    .ant-select:not(:last-child) .ant-select-selector {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .ant-select~.input-group-addon {
        border-radius: 0 4px 4px 0;
    }
}

.react-datepicker-popper {
    z-index: 99999;
}

.ant-modal-footer {
    padding: 1.5rem 1rem;
}

.ant-modal {
    color: #5c5c5c;
}

.ant-btn.btn-primary:disabled {
    color: $white;
}

.ant-btn.btn-outline-secondary {
    font-size: 0.875rem;
    border-radius: 0.25rem;
    &:hover,
    &:focus,
    &:active {
        background-color: transparent !important;
        border-color: $default !important;
        color: $primary !important;
        box-shadow: none !important;
    }
}

.fixed-cta .ant-btn {
    height: 42px;
}

.ant-btn.btn-outline-primary:not(:disabled) {
    &:hover {
        color: $primary;
    }
}

.emrfrm {
    .ant-btn {
        padding: 0.25rem 1rem;
        height: auto;
        border-radius: 5px;
        font-size: 1rem;
        line-height: 1.5;
    }
    .ant-input {
        font-size: 1rem;
        border-radius: 0.25rem;
        height: calc(2rem - 2px);
        position: relative;
        box-shadow: none !important;
        height: 33px;
        &:focus,
        &:hover {
            border-color: $default !important;
            box-shadow: none !important;
            &~.ant-input-group-addon {
                border-color: $default;
            }
        }
    }
    .ant-input-group-addon {
        border-radius: 0.25rem;
        font-size: 1rem;
        background-color: #fff;
    }
    .ant-input-group>.ant-input:last-child,
    .ant-input-group-addon:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-form-item-has-error .ant-input {
        background: #fff !important;
    }
    .antInputItem,
    .antSelectItem {
        .ant-form-item {
            position: relative;
            .ant-form-item-label {
                position: absolute;
                transition: all 0.1s ease-in-out;
                // top: 10px;
                left: 10px;
                outline: 0;
                z-index: 99;
                label {}
            }
        }
        &.hasdata {
            .ant-form-item-label {
                top: -12px;
                display: inline-block;
                padding: 0 3px;
                left: 10px;
                background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $white 50%, $white 100%);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, $white 50%, $white 100%);
                label {
                    font-size: 12px;
                    color: $primary;
                }
            }
            &.field_disabled {
                .ant-input-disabled {
                    color: #888;
                }
                .ant-form-item-label {
                    background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #f5f5f5 50%, #f5f5f5 100%);
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #f5f5f5 50%, #f5f5f5 100%);
                }
            }
        }
        .datetimepicker {
            line-height: 30px!important;
        }
        &.multi-col {
            .ant-form-item-control {
                z-index: 991;
                .multi-input-col-inner .ant-input-wrapper div.ant-input {
                    padding-left: 0;
                    background: transparent;
                    position: relative;
                    &:after {
                        content: "/";
                        display: none;
                        position: absolute;
                        left: 40%;
                        top: 5px;
                        font-size: 22px;
                        color: #aaa;
                        font-weight: 300;
                    }
                    .ant-input {
                        width: 50%;
                        position: relative;
                        background: transparent;
                        top: -4px;
                        border: none;
                    }
                }
            }
            &.hasdata {
                .ant-form-item-label {
                    z-index: 992;
                }
                .multi-input-col-inner .ant-input-wrapper div.ant-input {
                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
    .ant-select-multiple .ant-select-selection-search {
        overflow: hidden;
    }
}

.ant-notification-notice-close {
    top: 5px;
    right: 10px;
}

.ant-modal-content {
    border-radius: 0.5rem;
    .ant-modal-header {
        border-radius: 0.5rem 0.5rem 0 0;
        .ant-modal-title {
            font-size: 1.125rem;
            color: #5c5c5c;
            padding-top: 0px;
            height: 20px;
        }
    }
}

.add_component_modal {
    .mt-4 {
        margin-top: 0px !important;
    }
    .text-heading {
        display: none !important;
    }
    #create-medicine-details .my-4 {
        margin-top: 0px !important;
    }
}

#template-select_list+.rc-virtual-list {
    .ant-select-item-option {
        height: 56px;
    }
}

.ant-select {
    &.ant-select-single {
        .template-S1 {
            display: inline-flex;
            &::after {
                content: "|";
                padding: 0px 4px;
            }
        }
        .template-S2 {
            display: inline-flex;
        }
        .template-S3 {
            position: relative;
            top: -2px;
        }
    }
}

.add_component_modal {
    .mt-4 {
        margin-top: 0px !important;
    }
    .text-heading {
        display: none !important;
    }
    #create-medicine-details .my-4 {
        margin-top: 0px !important;
    }
    .modal-medicine {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.all_vital .ant-modal-content {
    .ant-modal-header {
        margin-bottom: 25px;
    }
    .ant-modal-body {
        padding-top: 0px;
    }
}

.single_vital .ant-modal-content .ant-modal-body {
    padding-top: 10px;
    position: relative;
    .wrap_chart_filter {
        z-index: 9;
        top: 45px;
    }
    .chartCanvas {
        padding-top: 50px;
    }
}

// .ant-modal-wrap {
//   background: rgba(0, 0, 0, 0.8);
// }
.ant-modal-wrap.pubnub_modal {
    z-index: 1050;
}

.medicine_star {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 70px;
        position: relative;
        .desc {
            max-width: calc(100% - 70px);
        }
        .float-right.text-primary {
            position: absolute;
            // top: 10px;
            right: 20px;
        }
    }
}

.custom_drug_select {
    .ant-select-item-option {
        min-height: 40px;
        height: auto;
        .ant-select-item-option-content {
            width: 100%;
            position: relative;
            white-space: unset;
            .desc {
                max-width: calc(100% - 50px);
                display: block;
            }
            .float-right.text-primary {
                width: 40px;
                position: absolute;
                top: 2px;
                right: -20px;
            }
        }
        &.ant-select-item-option-active,
        &.ant-select-item-option-selected {
            .ant-select-item-option-content .float-right.text-primary {
                .icon_star:after {
                    @include star_icon($fill_white);
                }
            }
        }
    }
}

.ant-select-selection-overflow {
    // padding-top: 5px;
}

.ant-checkbox-wrapper {
    .ant-checkbox-inner {
        width: 1.125rem;
        height: 1.125rem;
        border-color: $color_gray_border;
        outline: none;
        &:hover {
            border-color: $primary;
        }
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            border-color: $primary;
            background-color: transparent;
            &::after {
                border-color: $primary;
                width: 5.5px;
                height: 12px;
                top: 42%;
            }
        }
        &:hover {
            &::after {
                border-color: $primary;
            }
            &::after {
                animation: none;
            }
        }
    }
    &:hover {
        .ant-checkbox-inner {
            border-color: $primary;
        }
        .ant-checkbox::after {
            border-color: $primary;
        }
    }
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: $primary !important;
    box-shadow: none !important;
    border-radius: 0.25rem !important;
}

.ant-picker.ant-picker-disabled {
    background: #fff !important;
    border-color: #d9d9d9 !important;
    cursor: not-allowed;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $default !important;
    box-shadow: none !important;
}

.ant-select-focused {
    border-color: $primary !important;
    box-shadow: none !important;
    border-radius: 0.25rem !important;
}

.ant-select-focused:not(.ant-select-disabled),
.ant-select:not(.ant-select-customize-input),
.ant-select-selector {
    border-color: $color_gray_border !important;
    box-shadow: none !important;
    border-radius: 4px!important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #d24350 !important;
    color: white !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 600 !important;
    background-color: #d24350 !important;
    color: white !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $primary !important;
}

.ant-picker-today-btn {
    color: $primary !important;
    &:active {
        color: $primary !important;
    }
    &:hover {
        color: $primary !important;
    }
}

.ant-picker-now-btn {
    color: $primary !important;
    &:active {
        color: $primary !important;
    }
    &:hover {
        color: $primary !important;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid $primary !important;
}

.ant-picker-header-view button {
    &:hover {
        color: $primary !important;
    }
}

.ant-btn-primary {
    color: $color_white !important;
    background: $primary !important;
    border-color: $primary !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: $primary !important;
    color: $color_white !important;
}

.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: $secondary !important;
}

//  .ant-input:hover{
//    border-color:$default !important;
//    box-shadow: none !important;
//  }
.ant-input:focus {
    border-color: $default !important;
    box-shadow: none !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: transparent !important;
    &:after {
        background-color: transparent !important;
    }
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: $primary !important;
}

// .form-group.input-group:hover {
//   border-color: #f44336;
//  }
.textChang {
    height: 31px;
}

.inpChang {
    //  height: 44px;
}

.custom-control.custom-checkbox.custom-control-inline.mt-2.cust-chck {
    margin-top: 0.8rem !important;
}

.text-mar {
    margin-top: 6px;
}

.icon_star::before {
    //background-image: url(data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 487.222 487.222' xml:space='preserve' style='fill:%23d24350;'%3E%3Cg%3E%3Cpath d='M486.554,186.811c-1.6-4.9-5.8-8.4-10.9-9.2l-152-21.6l-68.4-137.5c-2.3-4.6-7-7.5-12.1-7.5l0,0c-5.1,0-9.8,2.9-12.1,7.6 l-67.5,137.9l-152,22.6c-5.1,0.8-9.3,4.3-10.9,9.2s-0.2,10.3,3.5,13.8l110.3,106.9l-25.5,151.4c-0.9,5.1,1.2,10.2,5.4,13.2 c2.3,1.7,5.1,2.6,7.9,2.6c2.2,0,4.3-0.5,6.3-1.6l135.7-71.9l136.1,71.1c2,1,4.1,1.5,6.2,1.5l0,0c7.4,0,13.5-6.1,13.5-13.5 c0-1.1-0.1-2.1-0.4-3.1l-26.3-150.5l109.6-107.5C486.854,197.111,488.154,191.711,486.554,186.811z M349.554,293.911 c-3.2,3.1-4.6,7.6-3.8,12l22.9,131.3l-118.2-61.7c-3.9-2.1-8.6-2-12.6,0l-117.8,62.4l22.1-131.5c0.7-4.4-0.7-8.8-3.9-11.9 l-95.6-92.8l131.9-19.6c4.4-0.7,8.2-3.4,10.1-7.4l58.6-119.7l59.4,119.4c2,4,5.8,6.7,10.2,7.4l132,18.8L349.554,293.911z'/%3E%3C/g%3E%3C/svg%3E%0A);
    color: white;
}

.ant-input {
    height: 31px;
}

.ant-form-item-label {
    top: 5px;
}

.handle_slash::after {
    top: -7px !important
}

.hightlight-input input {
    border-color: $default!important;
}

.ant-input-textarea-show-count:after {
    display: none!important
}

.textareheight {
    height: 33px!important
}

.border-0 .ant-select-selector {
    border: 0px!important;
    height: 29px!important
}

.boxe-mod {
    width: 300px;
    background: red;
    height: 300px;
}

.ant-modal-wrap1,
.ant-notification {
    z-index: 1000;
}