.-sort-desc {
  box-shadow: none !important;
  &:before {
    content: "▼";
    float: right;
  }
}

.-sort-asc {
  box-shadow: none !important;
  &:before {
    content: "▲";
    float: right;
  }
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}

.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
  border-top-right-radius: 1px !important;
  border-bottom-right-radius: 1px !important;
}
.emrfrm .form-label-group .form-control {
  border-right: block !important;

}
