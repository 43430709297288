 @import "./variables";

.bg-yellow {
    background-color: $warning;
}

.bg-red {
    background-color: $primary;
}

.card-n {
    position: absolute;
    width: 300px;
    z-index: 99999;
}
.popover-body {
    border: 1px solid #8b8b8b30;
    border-radius: 4px;
    background: white;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    .dropdown-item {
        color: #333 !important;
        font-size: 14px;
        &:hover {
            background: #f4f4f4 !important;
            border-radius: 4px;
        }
    }
}
.bs-popover-auto[x-placement^=bottom]>.arrow, .bs-popover-bottom>.arrow{
    top: calc(-.45rem - 1px)!important;

}
.card-dropdown {
    border: 1px solid #8b8b8b30;
    background: white;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.card-dropdown .dropdown-item {
    color: #333 !important;
    font-size: 14px;
}
.card-dropdown .dropdown-item:hover {
    background: #f4f4f4 !important;
}

.bg-blue {
    background-color: #0099ff;
}

.bg-purple {
    background-color: #b92dc4;
}

.bg-grey {
    background-color: $bg_dark;
}

.img-fluidd {
    max-width: 100%;
    height: auto;
    border-radius: 70px;
}

.card.border-success:active {
    background-color: $success;
}

.card.border-success:active:after {
    background-color: $success;
}

.card.border-secondary:active {
    background-color: $bg_dark;
}

.card.border-secondary:active:after {
    background-color: $bg_dark;
    color: white !important;
}

.card.border-warning:active {
    background-color: $warning;
}

.card.border-warning:active:after {
    background-color: $warning;
}

.card.border-blue {
    border-color: #0099ff !important;
}
.card.border-blue:active {
    background-color: #0099ff !important;
}
.card.border-blue:active:after {
    background-color: #0099ff !important;
}

.card.border-red {
    border-color: $primary !important;
}

.card.border-yellow {
    border-color: $warning !important;
}

.card.border-grey {
    border-color: #686868 !important;
}
.card.border-purple {
    border-color: purple !important;
}



.his-icon.icon-ic-bed.green {
    color: $success;
    font-size: 2.3rem;
}

.his-icon.icon-ic-bed.secondary {
    color: $bg_dark;
    font-size: 2.3rem;
}

.his-icon.icon-ic-bed.yellow {
    color: $warning;
    font-size: 2.3rem;
}

.his-icon.icon-ic-bed.grey{
    color:  grey;
    font-size: 2.3rem;
}
.back-grey:hover .card {
    background:#686868 !important;
    color: white;
    border-radius: 4px;
}

.his-icon.icon-ic-bed.purple{
    color:  purple;
    font-size: 2.3rem;
}
.back-purple:hover .card {
    background: #875F9A !important;
    color: white;
    border-radius: 4px;
}

.his-icon.icon-ic-bed.red {
    color: $primary;
    font-size: 2.3rem;
}
.his-icon.icon-ic-bed.red:after {
    /* background-color: $primary !important; */
    color: white !important;
}

.his-icon.icon-ic-bed.red:active {
    /* background-color: $primary !important; */
    color: white !important ;
}

.btn.ontap:after {
    background-color: $warning !important;
    border: none;
}

.btn.ontap:active {
    background-color: $warning !important;
    border: none;
}

.btn.ontap.secondary:after {
    background-color: $bg_dark !important;
    border: none;
}

.btn.ontap.secondary:active {
    background-color: $bg_dark !important;
    border: none;
}

.btn.ontap.blue:after {
    background-color: #0099ff !important;
    border: none;
}

.btn.ontap.blue:active {
    background-color: #0099ff !important;
    border: none;
}


.btn.ontap.red:after {
    background-color: $primary !important;
    border: none;
}

.btn.ontap.red:active {
    background-color: $primary !important;
    border: none;
}

.btn.ontap.green:after {
    background-color: $success !important;
    border: none;
}

.btn.ontap.green:active {
    background-color: $success !important;
    border: none;
}

.back-purple{


    border:1px solid rgb(255, 255, 255);
    .card{
        &:hover{background: purple!important; color: white; border-radius: 4px;
        }
    }
    &:hover{border:1px solid purple !important;  border-radius: 4px;
        .card{background: purple !important; color: white; border-radius: 4px;}

        i{color:white!important}
    }

}

.back-grey{

    
    .card{
    &:hover{background: #686868 !important; color: white; border-radius: 4px;
    }
    }
    &:hover{border:1px solid #686868 !important; border-radius: 4px;
    .card{background: #686868 !important; color: white; border-radius: 4px;}
    
    i{color:white!important}
    }
    
    }



.back-green{


    border:1px solid #fff;
    .card{
        &:hover{background: $success!important; color: white; border-radius: 4px;
        }
    }
    &:hover{border:1px solid $success!important;  border-radius: 4px;
        .card{background: $success!important; color: white; border-radius: 4px;}

        i{color:white!important}
    }

}
.back-yellow{


    border:1px solid #fff;
    .card{
        &:hover{background: $warning!important; color: white; border-radius: 4px;
        }
    }
    &:hover{border:1px solid $warning!important;  border-radius: 4px;
        .card{background: $warning!important; color: white; border-radius: 4px;}

        i{color:white!important}
    }
}
.back-blue{

    border:1px solid #fff;
    .card{
        &:hover{background: #0099ff!important; color: white; border-radius: 4px;
        }
    }
    &:hover{border:1px solid #0099ff!important;  border-radius: 4px;
        .card{background:  #0099ff!important; color: white; border-radius: 4px;}

        i{color:white!important}
    }

}
.back-black{
    border:1px solid #fff;
    .card{
        &:hover{background: $bg_dark!important; color: white; border-radius: 4px;
        }
    }
    &:hover{border:1px solid $bg_dark!important;  border-radius: 4px;
        .card{background: $bg_dark!important; color: white; border-radius: 4px;}

        i{color:white!important}
    }

}

.back-red{
    border:1px solid #fff;
    .card{
        &:hover{background: $primary!important; color: white; border-radius: 4px;
        }
    }
    &:hover{border:1px solid $primary!important;  border-radius: 4px;
        .card{background: $primary!important; color: white; border-radius: 4px;}
        i{color:white!important}
    }
}


