nav.aside-nav{background:#333; z-index:9999; min-height:calc(100% - 61px); width:50px; left:0px; top:61px; 
	position: absolute; border-right: solid 0.12rem $white;
	.aside-nav-wrap{position: sticky; top:0px}

	
	.menu-burger{font-size: 0px; width: 32px; cursor: pointer; position: absolute; z-index: 9; top:10px; right: 10px;
		.line-one, .line-two, .line-three {display: block; height: 3px; margin: 5px auto; width: 100%;  @include transition(0.3s); background:#ffffff;}
		
		&.on{
			.line-one{-webkit-transform: rotate(-45deg) translate(-5px, 5px); transform: rotate(-45deg) translate(-5px, 5px); width:80%}
			.line-two{opacity:0}
			.line-three{-webkit-transform: rotate(45deg) translate(-6px, -6px); transform: rotate(45deg) translate(-6px, -6px); width:80%}
		}
	}
.menu-arrow{
	background: url(../images/ic-arrow-down-white.svg) right/25px 10px transparent no-repeat;
}
.subchild{background: #5b5b5b; transition: .3s ease all;}
.menu-arrow-up{
	background: url(../images/ic-arrow-up.svg) right/25px 10px transparent no-repeat !important;

}
	.aside-nav-container{display: block; opacity: 0; overflow: hidden;
		.nav-module-warp{width: 100%; @include transition(0.2s); left: -300px; position: relative; display: block;}

	}

	&.on{@include transition(0.2s); border-right-color: $default;  width: 300px;
		.menu-burger{display: none;}
		.aside-nav-container{opacity: 1; 
			.nav-module-warp{left: 0px;}
		}
		
	}
	

	.nav-module-list{position: relative;
		padding:0px 8px;
		.btn{ width:100%; position: relative; color: $white; text-align: left; padding: 0.5rem; text-transform: uppercase; background:#d24350; margin-bottom:10px;
			i{position: absolute; font-size: 14px; top:12px; right: 15px; }
		}
		ul{transform:none!important; top:36px!important; left:4px!important; width: calc(100% - 9px); padding:0px; margin: 0px; max-height:calc(100vh - 150px); overflow-y:auto;
			li{padding: 7px 10px; border-bottom: solid 1px $border-color; text-transform: uppercase; cursor: pointer;
				&.active, &:hover{background-color: $bg_lightgrey;}
			}
		}
	}

	.nav-item-search{padding: 6px; 
		.form-control{background: $bg_card; border: none;}
		.btn{background: $bg_card; border-radius: 0px .25rem .25rem 0; max-width: 46px; padding: 0px 10px}
	}

	.module-item-list{
		ul{list-style: none; margin: 0px; padding: 0px; display: none; // overflow-y: auto;
			
			li{border-bottom: solid 1px $left_menu_border; color: $white; font-size: 0.9rem; font-weight: 400; position: relative;
				span{display: block; padding: 7px 12px; }

				span.pindrop{border-radius: 50%; background:$white; position: absolute; right: 0.5rem; top:0.3rem; padding: 0px; margin: 0px; text-align: center;
					line-height: 1.5rem; width: 1.5rem; height: 1.5rem; z-index: 99; @include transition(0.2s); display: none; @include rotate(45deg);
					i{font-size: 12px; color:$primary}
				}

				a{color: $white; text-decoration: none; display: block; padding: 7px 12px;  position: relative; z-index: 1;
					&:hover{background-color: $left_menu_hover;
						span.pindrop{display: block;}
					}

					&.setpin span.pindrop{display: block; background-color: $bg_pindrop; @include rotate(0deg);
						i{color:$white}
					}

					&.setpinhover span.pindrop{display: block; background-color: $white;}
				}

				ul{display: block; transition: .3s ease all;
					li{border-top: solid 1px $left_menu_border; border-bottom:none; transition: .3s ease all;
						a{padding-left: 24px;transition: .3s ease all;}
					}
				}

				
				
			}
		}

	}

}
.nav_popover{background-color: $default; display: none; margin-left: 20px;
	&.show{display: block;}
	.arrow{left: calc(-0.5rem - 8px); width: 1rem; height: 2rem;
		&::before{border-width: 1rem 1rem 1rem 0;}
		&::after{border-width: 1rem 1rem 1rem 0; border-right-color: $default;}
	}
	.popover-body{padding: 0px; 
		border: none; 
		background: transparent;

		.pop_header{padding: 0.5rem 0.75rem; border-bottom: solid 1px #474747;
			small{display: block; color: $white; font-size: 0.9rem; line-height: 1.2;}
			strong{display: block; color: #fe8f8f; font-size: 1rem; font-weight: 600; line-height: 1.2;}
		}
		ul.active-setpin{list-style: none; margin: 0; padding: 0;
				li{list-style: none; border-bottom: solid 1px $left_menu_border; color:$white; font-size: 0.9rem; font-weight: 400; position: relative; padding: 7px 12px; cursor: pointer;
					&:hover{background-color: $left_menu_hover;}
					&:last-child{border-bottom-left-radius: 0.25rem; border-bottom-right-radius: 0.25rem;}
			}
		}
	}
} 

.nav-parent{ max-height: calc(100vh - 160px);overflow-y: auto;}





#wrapper {
	padding-right: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
  }
  
  #wrapper.toggled {
	padding-right: 250px;
  }
  
  #sidebar-wrapper {
	z-index: 9999;
	position: absolute;
	right: 0px;
	width: 450px;
	top: 59px;
	height: auto;
	opacity: 0;
	visibility: hidden;
	padding-bottom: 18px;
	overflow-y: auto;
	background: white;
	box-shadow:2px 5px 15px rgba(0,0,0,.21)!important;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
  }
  
  #wrapper.toggled #sidebar-wrapper {
	width: 450px;
	margin-top: 0px;
	transition: .4s ease-in all;
	opacity: 1;
	visibility: visible;
  }
  
  #page-content-wrapper {
	width: 100%;
	position: absolute;
	padding: 15px;
  }
  
  #wrapper.toggled #page-content-wrapper {
	position: absolute;
	margin-left: -450px;
  }
  
  .blurmain{
	  filter: blur(1px);
	  opacity: .3;
	  pointer-events: none;
  }
  
  

  .hover-border{
	  .form-control{
		  border:1px solid #fff!important;
		  background: transparent!important;
		  &:hover{
			background: url(../images/ic-arrow-down.svg) right/30px 14px transparent no-repeat !important;

			border:1px solid #ccc!important;
		}		 
		&:disabled{border:0px!important; background:white!important; cursor:auto!important}

	  }
  }
  

  .close-pin{
	  
  }
  .active-menu + ul{display: block!important}