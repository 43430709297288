.row{margin-right: -$gutter; margin-left: -$gutter;}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{padding-left:$gutter; padding-right:$gutter}
.col-10p{width:10.25%}
#main{padding-left: 50px; min-height: calc(100vh - 122px); position: relative;}
.container-fluid{position: relative; padding-right: 10px; padding-left: 10px; min-height: inherit;}
@media (min-width: 1680px){.container-fluid{ 
    box-shadow: none;
}}
.fixed-cta{position: fixed; background: $white; left: 0; bottom: 0; width: 100%; z-index: 999;
    .container-fluid{padding:1rem 10px 1rem 60px; box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 4px 1px;}
}
.p-3{padding: 0.75rem!important;}
.px-3{padding-left: 0.75rem!important; padding-right: 0.75rem!important;}

/** SCROLL BAR CSS **/
 body {overflow: hidden; overflow-y: auto;}
 *::-webkit-scrollbar {  scrollbar-width: auto; width:10px; height:10px;
 }
 *::-webkit-scrollbar-track {background: #fafafa;}
 *::-webkit-scrollbar-thumb {background-color: #999; border-radius: 8px;}
 *::-webkit-scrollbar-thumb:hover {background: #5b5b5b;cursor: pointer}
::-webkit-scrollbar-track
{

	border-radius: 0px!important;
	background-color: white;
}

::-webkit-scrollbar
{
	border-radius: 0px!important;
	background-color: white;
}

::-webkit-scrollbar-thumb{
border-radius: 0px!important;
}



//  *::-webkit-scrollbar-thumb {background-color: #999; border-radius: 8px;}



.padding_less{padding: 3px 0; margin-top: 4px !important; margin-bottom: 4px !important;}
.custom-checkbox .custom-control-input{z-index: 30;}
.cke_bottom{background: none !important; border: none !important;}
.slash_input::after{top:-7px !important; position: absolute;}
.table_layout_popup{table-layout: fixed;}
.table_layout_popup td{word-break: break-all;}
.ant-radio-inner{width: 16px !important; height: 16px !important; border-radius: 16px !important; display: block; }
.ant-radio-inner:after{
	background: url(../images/radio-on.png) center/16px 16px transparent no-repeat !important;
    width: 16px!important;
    height: 16px!important;
	background-color:transparent!important;
    border: 0!important;
    background-size: contain;
    background-repeat: no-repeat;
    top: 7px!important;
	left: 7px!important;
    transform:scale(1)!important;

}
.search_text_suggession{width: calc(100% - 30px); display: inline-block; vertical-align: top; overflow: hidden; word-break: break-all; white-space: pre-wrap;}
.ant-select-item-option{min-height: 44px; height: auto !important;}
.remove_margin table{margin-bottom: 0;}
.active_medication_new{height: 146px; overflow-y: scroll;}
.margin_tp{margin-top: 5px !important;}
.ant-select-item-option-content .desc{width: calc(100% - 160px); display: inline-block; vertical-align: top; overflow: hidden; word-break: break-all; white-space: pre-wrap;}



// for mhvc
// #site-header {
//     max-width: 1580px;
//     margin: 0 auto 1rem;
//    }
//    #site-content {
//     min-height: 70vh;
//     max-width: 1580px;
//    }

// new main layout
#mainnew {
    min-height: calc(100vh - 200px);
    margin-left: auto;
    margin-right: auto;
    padding:0px 20px 0px 15px;
    width: 100%;
    margin: 0 auto;
    background: #ebebeb;
}

header{
    margin:0 auto;
}
.new-footer{
    padding: 10px 25px;
    background: #ebebeb;
    .footer-text{
    color:#333333
    }
    }
@media screen and (min-width: 1620px) {

    header,
    #mainnew {
        //max-width: 1580px;
        max-width: auto;
    }
}

@media screen and (min-width: 1921px) {

    header,
    #mainnew {
        max-width: 1780px;
    }
}

.myHealthcare-primary-header {
    //background-color: #fff;
    // background-color: #fff;
    border:solid 1px #ebebeb;
    padding: 15px 10px;
}
.myHealthcare-logo-container {
    max-width: 130px;
    min-width: 100px;
}
.myHealthcare-loggedin-user-name {
    padding: 6px 10px;
}
.myHealthcare-loggedin-user-image {
    border: 2px solid #dbdbdb;
    border-radius: 50%;
}
.img-md {
    width: 60px;
    height: 60px;
}
.myHealthcare-loggedin-user-name .dropdown>a {
    color: rgb(96, 96, 96);
    font-size: 1rem;
    font-family: 'Raleway', sans-serif; 
    line-height: 1.2;
    text-align: right;
    padding: 0;
    text-decoration: none;
}
.myHealthcare-loggedin-user-name .myHealthcare-loggedin-user-type {
    font-size: 0.875rem;
    font-family: 'Raleway', sans-serif;
    color: #d24350;
    line-height: 1.1;
    text-align: right;
    padding: 4px 5px;
}

// new nav bar css
.newnavbar.navbar-dark {
    padding:0 0px !important;
    .navbar-nav {
        .active {
            >.nav-link {
                color: #fff;
                border-left: none;
                outline: none !important;
            }
        }

        .nav-link {
            color: white !important;
            padding: 0.55rem 0.75rem;
            font-size: 0.9rem;
            position: relative;
             &:hover{
                     background-color: #d24350;
                 }
        }

        .nav-item {
            &:first-child {
                .nav-link {
                    color: white !important;
                }
            }
            // &:hover{
            //     background-color: #000;
            // }
        }
    }

    .nav-item {
        &:not(:last-child) {
            &:after {
                content: "";
                display: block;
                position: absolute;
                right: -1px;
                width: 1px;
                height: 22px;
                top: 7px;
                background: #B2B2B2;
            }
        }
        // &.active{
        //     background-color: #d24350;
        // }
        // :hover {
        //     background-color: #d24350;
        // }
    }
}

.navbar-dark {
    .navbar-nav {
        .nav-link.active {
            color: #fff;
            border-left: none;
        }

        .nav-link.show {
            color: #fff;
            border-left: none;
        }

        .show {
            >.nav-link {
                color: #fff;
                border-left: none;
            }
        }
    }
}

.newnavbar {
    // .nav-item.dropdown.active.show {
    //     background-color: #d24350 !important;
    // }
    .navbar-nav {
        .dropdown-menu {
            background: #333333 !important;
        }
    }
    
    .dropdown-item {
        display: block;
        width: 100%;
        padding: .25rem 1rem !important;
        clear: both;
        font-weight: normal;
        font-size: 0.9rem;
        color: #fff;
        text-align: inherit;
        white-space: nowrap;
        background: 0 0;
        border-bottom: solid 0.1rem #666161;
        

        &:last-child {
            border-bottom: none;
        }
                &:active {
                    color: #fff;
                    text-decoration: none;
                    background-color: #d24350 !important;
                }
    }

    .dropdown-menu {
        padding: 0.3rem 0rem 0rem 0rem;
        border-color: none !important;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 10rem;
        padding: 0rem;
        margin: .05rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: none;
        border-radius: 0rem;
        
    }

    .dropdown-menu.show {
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgb(52, 58, 64);
            border-radius: 10px;
            background-color: rgb(52, 58, 64);
        
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px #ccc;
        }
    }

    .dropdown-toggle.no-arrow {
        &::after {
            display: none;
        }
    }

    .navbar-collapse.collapse.show {
        background-color: #343a40;
    }

    #navbarSupportedContent {
        background: #343a40 !important;
    }
}

.dropdown-item.newdropitem {
    &:focus {
        text-decoration: none;
        color: #fff;
        background-color: #d24350 !important;
    }

    &:hover {
        text-decoration: none;
        color: #fff !important;
        background-color: #d24350 !important;
    }
}

@media screen and (min-device-width: 1201px) {
    .newnavbar.navbar-dark {
        .navbar-nav {
            .nav-link {
                color: white !important;
                padding: 0.55rem 0.90rem;
                font-size: 0.95rem;
                min-height: auto;
                letter-spacing: .30px;
            
           
            }
        }

        .nav-item {
            &:not(:last-child) {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: -1px;
                    width: 1px;
                    height: 22px !important;
                    top: 7px;
                    background: #B2B2B2;
                }
            }
            &.active.show{
                &:not(:last-child) {
                        &:after {
                            content: "";
                            display: none;
                            position: absolute;
                            right: -1px;
                            width: 1px;
                            height: 22px !important;
                            top: 7px;
                            background: #B2B2B2;
                        }
                    }
            }
        }
    }
}

@media screen and (max-device-width: 1200px) {
    .newnavbar.navbar-dark {
        .navbar-nav {
            .nav-link {
                color: white !important;
                padding: 0.55rem 0.75rem;
                font-size: 0.95rem;
                position: relative;
                border-bottom: solid 1px #2b2b2b;
            }
        }
    }
}

@media screen and (min-width:1200px) {
    .newnavbar {
        .dropdown-menu.show {
            display: block;
            max-height: calc(100vh - 170px);
            overflow-y: auto;
            width: auto;
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }
}

@media screen and (max-width:1199px) {
    .newnavbar {
        .dropdown-menu.show {
            display: block;
            max-height: calc(100vh - 170px);
            overflow-y: auto;
            width: 100%;
            position: relative;
        }
                
    }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
    .newnavbar {
        .dropdown-toggle {
            &::after {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: 0.45em solid;
                border-right: 0.45em solid transparent;
                border-bottom: 0;
                border-left: 0.45em solid transparent;
                position: absolute;
                right: 20px;
                top: 10px;
            }
        }

        .nav-item.dropdown.active.show {
            .dropdown-toggle {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

@media screen and (max-width:991px) {
    .newnavbar {
        .dropdown-toggle {
            &::after {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: 0.45em solid;
                border-right: 0.45em solid transparent;
                border-bottom: 0;
                border-left: 0.45em solid transparent;
                position: absolute;
                right: 50px;
                top: 10px;
            }
        }

        .nav-item.dropdown.active.show {
            .dropdown-toggle {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

/** SCROLL BAR CSS **/

body {
    overflow: hidden;
    // overflow-y: scroll;
    overflow-y: auto;
}

*::-webkit-scrollbar {
    width: 0.45rem;
}

*::-webkit-scrollbar-thumb {
    background-color: #666666;
    border-radius: 10px;
}
// *::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 0px transparent;
//     border-radius: 10px;
//     background-color: transparent;
// }
// login box
.login-box {
    width: 400px;
    margin: auto;
    position: absolute;
    top:40%;
    left:50%;
    transform: translate(-50%,-50%);

    .login-logo {
        text-align: center;
        padding-bottom: 10px;

        .login-logo {
            width: 150px;
            height: auto;
        }
    }

    .login-box-body {
        box-shadow: 0 0 6px 1px lightgray;
        background-color: #fafbfb;
        padding: 1.5rem;
        min-height: 13rem;
    }

    .help-block {
        color: #d24350;
    }

    .border-right {
        border: solid 1px #ccc;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 0.2rem 1rem;
    }
}
/* in navitem */
.active-link {
    background-color: #d24350 !important;
}

/* in dropdown-item */
.active-submenu-link {
    background-color: #d24350 !important;
}
.newdropitem.active,
.newdropitem:active {
    color: #fff;
    text-decoration: none;
    background-color: #d24350 !important;
}

@media screen and (max-width: 991px) {
    .newnavbar .dropdown-toggle::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.45em solid;
        border-right: 0.45em solid transparent;
        border-bottom: 0;
        border-left: 0.45em solid transparent;
        position: absolute;
        right: 45px;
        top: 10px;
    }
}

@media screen and (max-width: 575px) {
    .newnavbar .dropdown-toggle::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.45em solid;
        border-right: 0.45em solid transparent;
        border-bottom: 0;
        border-left: 0.45em solid transparent;
        position: absolute;
        right: 30px;
        top: 10px;
    }
}