[class*="his-icon-"],
[class^=his-icon-] {
	position: relative;
	display:inline-block &:before {
		content: '';
		display: block;
		position: relative;
		width: 20px;
		height: 20px
	}
}
// @font-face {
// 	font-family: 'his-icon';
// 	src: url('../fonts/his-icon.eot');
// 	src: url('../fonts/his-icon.eot#iefix') format('embedded-opentype'), url('../fonts/his-icon.woff') format('woff'), url('../fonts/his-icon.ttf') format('truetype'), url('../fonts/his-icon.svg#his-icon') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }
.his-icon {
	font-family: "his-icon";
	font-style: normal;
	font-weight: normal;
	speak: never;
	display: inline-block;
	text-decoration: inherit;
	font-size: 1rem;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}










@font-face {
    font-family: 'his-icon';
    src: url('../font/his-icon.eot?59525108');
    src: url('../font/his-icon.eot?59525108#iefix') format('embedded-opentype'),
         url('../font/his-icon.woff2?59525108') format('woff2'),
         url('../font/his-icon.woff?59525108') format('woff'),
         url('../font/his-icon.ttf?59525108') format('truetype'),
         url('../font/his-icon.svg?59525108#his-icon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'his-icon';
      src: url('../font/his-icon.svg?59525108#his-icon') format('svg');
    }
  }
  */
   
  //  [class^="icon-"]:before, [class*=" icon-"]:before {
  //   font-family: "his-icon";
  //   font-style: normal;
  //   font-weight: normal;
  //   speak: never;
   
  //   display: inline-block;
  //   text-decoration: inherit;
  //   width: 1em;
  //   margin-right: .2em;
  //   text-align: center;
  //   /* opacity: .8; */
   
  //   /* For safety - reset parent styles, that can break glyph codes*/
  //   font-variant: normal;
  //   text-transform: none;
   
  //   /* fix buttons height, for twitter bootstrap */
  //   line-height: 1em;
   
  //   /* Animation center compensation - margins should be symmetric */
  //   /* remove if not needed */
  //   margin-left: .2em;
   
  //   /* you can be more comfortable with increased icons size */
  //   /* font-size: 120%; */
   
  //   /* Font smoothing. That was taken from TWBS */
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
   
  //   /* Uncomment for 3D effect */
  //   /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  // }
   
  .icon-search:before { content: '\e800'; } /* '' */
  .icon-ok:before { content: '\e801'; } /* '' */
  .icon-file:before { content: '\e802'; } /* '' */
  .icon-right-open-big:before { content: '\e803'; } /* '' */
  .icon-fall-risk-1:before { content: '\e804'; } /* '' */
  .icon-emp:before { content: '\e805'; } /* '' */
  .icon-ok-circled:before { content: '\e806'; } /* '' */
  .icon-ok-circled2:before { content: '\e807'; } /* '' */
  .icon-trash-empty:before { content: '\e808'; } /* '' */
  .icon-check:before { content: '\e809'; } /* '' */
  .icon-up-open-big:before { content: '\e80a'; } /* '' */
  .icon-up:before { content: '\e80b'; } /* '' */
  .icon-down:before { content: '\e80c'; } /* '' */
  .icon-left:before { content: '\e80d'; } /* '' */
  .icon-right:before { content: '\e80e'; } /* '' */
  .icon-left-open-big:before { content: '\e80f'; } /* '' */
  .icon-down-open-big:before { content: '\e810'; } /* '' */
  .icon-arrows-cw:before { content: '\e811'; } /* '' */
  .icon-down-circle:before { content: '\e812'; } /* '' */
  .icon-left-circle:before { content: '\e813'; } /* '' */
  .icon-right-circle:before { content: '\e814'; } /* '' */
  .icon-star-half:before { content: '\e815'; } /* '' */
  .icon-ic-calendar:before { content: '\e816'; } /* '' */
  .icon-bell:before { content: '\e817'; } /* '' */
  .icon-ic-bed:before { content: '\e818'; } /* '' */
  .icon-heart:before { content: '\e819'; } /* '' */
  .icon-heart-empty:before { content: '\e81a'; } /* '' */
  .icon-videocam:before { content: '\e81b'; } /* '' */
  .icon-mail:before { content: '\e81c'; } /* '' */
  .icon-linkedin:before { content: '\e81d'; } /* '' */
  .icon-twitter:before { content: '\e81e'; } /* '' */
  .icon-facebook:before { content: '\e81f'; } /* '' */
  .icon-youtube:before { content: '\e820'; } /* '' */
  .icon-email:before { content: '\e821'; } /* '' */
  .icon-googleplay:before { content: '\e822'; } /* '' */
  .icon-minus:before { content: '\e823'; } /* '' */
  .icon-plus:before { content: '\e824'; } /* '' */
  .icon-up-circle:before { content: '\e825'; } /* '' */
  .icon-star:before { content: '\e826'; } /* '' */
  .icon-star-empty:before { content: '\e827'; } /* '' */
  .icon-plus-circle:before { content: '\e828'; } /* '' */
  .icon-minus-circle:before { content: '\e829'; } /* '' */
  .icon-calculator:before { content: '\e82a'; } /* '' */
  .icon-ic-calculator:before { content: '\e82b'; } /* '' */
  .icon-ic-copy:before { content: '\e82c'; } /* '' */
  .icon-ic-edit:before { content: '\e82d'; } /* '' */
  .icon-plus-1:before { content: '\e82e'; } /* '' */
  .icon-plus-circle-1:before { content: '\e82f'; } /* '' */
  .icon-minus-circle-1:before { content: '\e830'; } /* '' */
  .icon-cancel-circled-outline:before { content: '\e831'; } /* '' */
  .icon-pin:before { content: '\e832'; } /* '' */
  .icon-ic-notification_red:before { content: '\e833'; } /* '' */
  .icon-shuffle:before { content: '\e834'; } /* '' */
  .icon-spin3:before { content: '\e835'; } /* '' */
  .icon-lock:before { content: '\e836'; } /* '' */
  .icon-lock-open:before { content: '\e837'; } /* '' */
  .icon-pencil-1:before { content: '\e838'; } /* '' */
  .icon-resize-full-alt:before { content: '\e839'; } /* '' */
  .icon-indent-left:before { content: '\e83a'; } /* '' */
  .icon-indent-right:before { content: '\e83b'; } /* '' */
  .icon-upload:before { content: '\e83c'; } /* '' */
  .icon-attention:before { content: '\e83d'; } /* '' */
  .icon-attention-circled:before { content: '\e83e'; } /* '' */
  .icon-link:before { content: '\e83f'; } /* '' */
  .icon-attach:before { content: '\e840'; } /* '' */
  .icon-info:before { content: '\e841'; } /* '' */
  .icon-user:before { content: '\e842'; } /* '' */
  .icon-attach-1:before { content: '\e843'; } /* '' */
  .icon-pencil-2:before { content: '\e844'; } /* '' */
  .icon-tags:before { content: '\e845'; } /* '' */
  .icon-eye:before { content: '\e846'; } /* '' */
  .icon-eye-off:before { content: '\e847'; } /* '' */
  .icon-lock-1:before { content: '\e848'; } /* '' */
  .icon-location:before { content: '\e849'; } /* '' */
  .icon-search-1:before { content: '\e84a'; } /* '' */
  .icon-pinboard:before { content: '\e84b'; } /* '' */
  .icon-clock:before { content: '\e84c'; } /* '' */
  .icon-download:before { content: '\e84d'; } /* '' */
  .icon-spin5:before { content: '\e84e'; } /* '' */
  .icon-spin4:before { content: '\e84f'; } /* '' */
  // .icon-cancel:before { content: '\e850'; } /* '' */
  .icon-ccw:before { content: '\e851'; } /* '' */
  .icon-cw:before { content: '\e852'; } /* '' */
  .icon-arrows-ccw:before { content: '\e853'; } /* '' */
  .icon-camera:before { content: '\e854'; } /* '' */
  .icon-cancel-circled:before { content: '\e855'; } /* '' */
  .icon-down-1:before { content: '\e856'; } /* '' */
  .icon-up-1:before { content: '\e857'; } /* '' */
  .icon-mic-off:before { content: '\e858'; } /* '' */
  .icon-camera-1:before { content: '\e859'; } /* '' */
  .icon-folder:before { content: '\e85a'; } /* '' */
  .icon-ic-vip:before { content: '\e85b'; } /* '' */
  .icon-ic-hand:before { content: '\e85c'; } /* '' */
  .icon-down-open:before { content: '\f004'; } /* '' */
  .icon-up-open:before { content: '\f005'; } /* '' */
  .icon-right-open:before { content: '\f006'; } /* '' */
  .icon-left-open:before { content: '\f007'; } /* '' */
  .icon-move:before { content: '\f047'; } /* '' */
  .icon-mic:before { content: '\f048'; } /* '' */
  .icon-ok-circled-1:before { content: '\f06d'; } /* '' */
  .icon-info-circled:before { content: '\f085'; } /* '' */
  .icon-link-ext:before { content: '\f08e'; } /* '' */
  .icon-menu:before { content: '\f0c9'; } /* '' */
  .icon-sort-down:before { content: '\f0dd'; } /* '' */
  .icon-sort-up:before { content: '\f0de'; } /* '' */
  .icon-bell-alt:before { content: '\f0f3'; } /* '' */
  .icon-doc-text:before { content: '\f0f6'; } /* '' */
  .icon-angle-double-left:before { content: '\f100'; } /* '' */
  .icon-angle-double-right:before { content: '\f101'; } /* '' */
  .icon-star-half-alt:before { content: '\f123'; } /* '' */
  .icon-unlink:before { content: '\f127'; } /* '' */
  .icon-lock-open-alt:before { content: '\f13e'; } /* '' */
  .icon-link-ext-alt:before { content: '\f14c'; } /* '' */
  .icon-dollar:before { content: '\f155'; } /* '' */
  .icon-toggle-off:before { content: '\f204'; } /* '' */
  .icon-toggle-on:before { content: '\f205'; } /* '' */
  .icon-heartbeat:before { content: '\f21e'; } /* '' */
  .icon-calendar-plus-o:before { content: '\f271'; } /* '' */
  .icon-calendar-minus-o:before { content: '\f272'; } /* '' */
  .icon-calendar-times-o:before { content: '\f273'; } /* '' */
  .icon-calendar-check-o:before { content: '\f274'; } /* '' */
  .icon-user-circle:before { content: '\f2bd'; } /* '' */
  .icon-user-circle-o:before { content: '\f2be'; } /* '' */
  .icon-pinterest:before { content: '\f312'; } /* '' */


  .ic-search {
    max-height: 18px; }
    .ic-search.border-left {
      padding-left: 10px;
      display: inline-block;
      border-left: solid 1px #ccc !important; }
  
  .ic-scan {
    width: 14px;
    height: 14px; }
    .ic-scan:before {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      background: url(../images/ic-scan.svg) no-repeat center center;
      background-size: cover; }
  
      .icon_bed {
        font-style: normal;
        width: 20px;
        height: 20px;
        display: inline-block;
        
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          @include bed_icon($icon_black);
          background-repeat: no-repeat
        }
      }

      .icon_note {
        font-style: normal;
        width: 20px;
        height: 20px;
        display: inline-block;
        
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          @include note_icon($icon_black);
          background-repeat: no-repeat
        }
      }