//@media (min-width: 1200px){ .modal-xl {max-width: 1000px;}}
//@media (min-width: 1200px){ .modal-xxl {max-width: 1200px;}}
.modal-backdrop{z-index: 10400; }
.modal{z-index: 10500;
    .modal-dialog{max-width: 500px;
        .modal-content{border-radius: 0.5rem;
            .modal-header{padding:1rem 2rem 0 0; border: none;
                .modal-title{font-size: 1.2rem; font-weight: 400; margin: 0px; padding: 0px;}
                .close{position: relative; outline: none; border: none; margin:-0.6rem -1.6rem -1rem auto; z-index: 1;
                    span{display: none;}
                    &:focus, &:hover{outline: none; border: none;}
                    &:before, &:after {content: ''; width:60%; height: .12rem; background:$black; display: inline-block; position: absolute;
                        transform: rotate(45deg); top:1rem;  right: 0.5rem;}
                    &:after{transform: rotate(135deg);}
                }
            }
            .modal-body{padding: 0;

            }
        }
        &.modal-xxs {max-width: 200px;}

        &.modal-xs {max-width: 400px;}
        &.modal-md {max-width: 600px;}
        &.modal-lg {max-width: 800px;}
        &.modal-xl {max-width: 1000px;}
        &.modal-xxl {max-width: 1200px;}
        &.modal-100{ max-width: 1500px;}
    }





/* ############## Modal Profile Image ###################### */
.modal_profile_image{max-width: 380px;
    .modal-content{padding:0.5rem 1rem 1rem;}
    .modal-header{font-size: 1rem; border: none; padding: 0 0 0.25rem;}
    .modal-body{border: solid 1px $border-color; border-radius: .25rem; padding:1rem;
        .modal-profile-user{background: $bg_alice_blue; border: solid 1px $border-color; text-align: center; max-height: 320px; overflow: hidden;  /* 1.37x1.77*/}
        .hidefile{position: relative;
             input[type="file"]{position: absolute; top: 0; left: 0; display: block; width: 100%; min-height: 100%; cursor: pointer; opacity: 0; z-index: 9;}
        }
    }
    
    .btn-outline-primary{
        &:hover, &:active, &:focus{border-color: $primary-hover!important; color:$white!important; background-color: $primary-hover!important;}
    }
}


.modal-img{position: relative; width: auto; display: inline-block; margin: 0;
    a{position: absolute; width: 22px; height: 22px; line-height: 22px; top: 5px; right: 5px; z-index: 9; 
        background:$white; color: $default;  border-radius: 50%; text-align: center; border: solid 0.12rem $default;  text-decoration: none; outline: none;
    }
    img{position: relative; z-index: 1;}
}


}


.shadowbody-modal {
    .modal-content{
        box-shadow: 0px 0px 10px rgb(80, 79, 79)!important;
    }
}

.modal-emr-periOP {
    .modal-dialog{
        max-width:1000px
}
}