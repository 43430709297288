$gutter: 5px;
$default: #333; 
$primary: #d24350;
$primary-hover: #d24350;
$success: #74a732;
$white:#fff;
$black:#000;
$danger: #ff5252;
$warning:#f39104;
$yellow:#F1B72D;
$text_muted:#ccc;
$default-color:#5c5c5c;

$disabled:#f6f6f6;


$left_menu_hover:#434343;
$left_menu_border:#2b2b2b;
$bg_pindrop:#595959;

$bg_primary:$primary;
//$bg_secondary:
$bg_success:$success;
$bg_danger:$danger;
$bg_warning:$warning;
//$bg_info:

$bg_card:#f4f4f4;
$bg_grey:#999999;
$bg_dark:#5c5c5c;
$bg_alice_blue:#f0f4f7;
$bg_lightgrey:#eff0f0;
$bg_light_smoke:#f8f9fa;
$bg_suva_grey: #8b8b8b;



$border-color:#ccc;
$border-hover-color:#999;

$bg_error:#fff3f4;
$error_border_color:#fca2a2;
$error_color:#ff0000;





$table_thead_bg: #8b8b8b;

// For icon
$icon_black: 000;