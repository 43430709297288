/* ############################## Date Time Picker ##########################################*/

.bootstrap-datetimepicker-widget {
	z-index: 99999;
	padding: 3px !important;
	max-width: 285px;
	ul li div {
		padding: 0px !important;
		margin: 0px !important;
	}
	table {
		background-color: $white !important;
		margin: 0px !important;
		padding: 0px !important;
		thead tr {
			background-color: $table_thead_bg !important;
			th {
				background-color: $table_thead_bg !important;
				border-radius: 0px !important;
				border: solid 1px rgba(0, 0, 0, 0.1) !important;
				color: $white !important;
				font-weight: 400 !important;
				text-transform: uppercase !important;
				padding: 0.5rem !important;
			}
		}
		tbody tr {
			background-color: $white !important;
			td {
				background: $white !important;
				border-radius: 0px !important;
				border: solid 1px rgba(0, 0, 0, 0.1) !important;
				padding: 0.5rem !important;
				width: 1rem !important;
				height: 1rem !important;
				line-height: 1rem !important;
				&.active {
					background-color: $bg_alice_blue !important;
					color: $black !important;
				}
				&.today {
					background-color: $primary !important;
					color: $white !important;
					&::before {
						display: none !important;
					}
				}
				&:hover {
					background-color: $bg_alice_blue !important;
				}
			}
		}
	}
	.btn.btn-primary {
		background-color: $bg_dark !important;
		border-color: $bg_dark !important;
	}
	.accordion-toggle td {
		background-color: #f9f9f9 !important;
		i {
			font-size: 20px;
			color: $bg_dark;
			cursor: pointer;
		}
		i.icon-ok {
			color: $success;
		}
	}
	.timepicker tr {
		background-color: #fff !important;
		td {
			border: none !important;
		}
	}
	table tfoot td i {
		font-size: 35px;
	}
}

.react-datepicker-popper {
	z-index: 99999 !important;
}

/* ############################## Select2 Style  ##########################################*/

.select2-container--default {
	.select2-selection--single {
		height: calc(2em + 2px) !important;
		border-color: $border-color !important;
		border-radius: 0.25rem !important;
		outline: none !important;
		.select2-selection__rendered {
			color: $default-color !important;
			line-height: 2rem !important;
		}
		.select2-selection__arrow {
			max-height: calc(100% - 3px);
			b {
				border: none !important;
				&::after {
					content: "\e80d";
					font-family: "his-icon";
					display: block;
					position: absolute;
					font-size: 0.9rem;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -25%);
				}
			}
		}
	}
	.select2-results {
		.select2-results__option {
			padding: 0.25rem 0.5rem !important;
		}
		//  .select2-results__option:empty{height: 0!important; padding: 0!important;}
		.select2-results__option:empty {
			height: 25px !important;
			padding: 0 !important;
		}
		.select2-results__option--highlighted[aria-selected] {
			background-color: $primary;
		}
		& > .select2-results__options {
			&::-webkit-scrollbar {
				width: 0.4rem;
			}
			&::-webkit-scrollbar-track {
				background: #fafafa;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #ededed;
				border-radius: 8px;
			}
			&::-webkit-scrollbar-thumb:hover {
				background: #ccc;
			}
		}
	}
	&.select2-container--open {
		.select2-selection--single .select2-selection__arrow b:after {
			content: "\e807";
		}
	}
}

.select2-container--disabled {
	.select2-selection--single {
		background-color: transparent !important;
		border-color: rgba(0, 0, 0, 0.1) !important;
		.select2-selection__rendered,
		.select2-selection__arrow {
			color: #b0b0b0 !important;
		}
	}
}

.gselect-bg-grey {
	padding: 0 !important;
	background-color: $bg_grey !important;
	color: $white;
	margin-right: -1px;
	.select2-container--default {
		padding: 0 10px;
		.select2-selection--single {
			height: calc(1.4em + 0.55rem + 2px) !important;
			border: none !important;
			border-radius: 0 !important;
			outline: none !important;
			background-color: transparent !important;
			.select2-selection__rendered {
				color: $white !important;
				padding-left: 0 !important;
				padding-right: 15px !important;
			}
			.select2-selection__arrow {
				right: -2px !important;
				b:after {
					font-size: 0.8rem;
				}
			}
		}
	}
	.select2-container--disabled {
		.select2-selection--single {
			border: none !important;
			.select2-selection__rendered,
			.select2-selection__arrow {
				color: #e3e3e3 !important;
			}
		}
	}
}

.select2-container {
	width: 100% !important;
	.select2-dropdown {
		z-index: 99999 !important;
	}
}
.react-select__indicator-separator,.css-1okebmr-indicatorSeparator {
	display: none!important; background:white!important;
}
.form_selected_control {
	z-index: 100;
	position: relative;
	.react-select__indicator-separator {
		display: none!important; background:white!important;
	}
	.react-select__control--is-disabled{
		background:white; cursor:not-allowed!important;
		.css-107lb6w-singleValue{
			color:#333!important; cursor:not-allowed!important;
		}
	}
	.react-select__control--is-disabled {
		min-height: calc(2rem + 2px)!important;
		z-index: 99; border-color: #ccc!important; cursor:not-allowed!important;
		.css-g1d714-ValueContainer,.react-select__value-container{
			padding: 0 8px!important;
		}
		.css-1hb7zxy-IndicatorsContainer {
			.css-1okebmr-indicatorSeparator,.react-select__indicator-separator {
				display: none;
			}
			.css-tlfecz-indicatorContainer,.react-select__indicators {
				padding: 0 8px!important;
			}
		}
	}
}

.gselect.border-0 + .select2-container--default,
.gselect_s.border-0 + .select2-container--default {
	.select2-selection--single {
		border-color: transparent !important;
	}
}

.border-left-0 .select2-container--default .select2-selection--single {
	border-left: none !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}

.border-right-0 .select2-container--default .select2-selection--single {
	border-right: none !important;
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

/* ############################## Bootstrap Multiselect ##########################################*/

.multiselect-native-select {
	width: 100%;
	height: calc(2rem + 2px);
	border: 1px solid $border-color;
	border-radius: 0.25rem;
	position: absolute !important;
	left: 0px;
	top: 0px;
	z-index: 991;
	.btn-group {
		width: 100%;
		height: 100%;
		z-index: 999;
		.dropdown-toggle,
		.multiselect-selected-text {
			width: 100%;
			text-align: left;
		}
		.dropdown-toggle::after {
			display: none !important;
		}
		.caret {
			width: 20px;
			height: 100%;
			position: absolute;
			top: 0px;
			right: 3px;
			&:after {
				content: "\e80d";
				font-family: "his-icon";
				display: block;
				position: absolute;
				font-size: 0.9rem;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -40%);
				color: $default-color;
			}
		}
	}
	.bg-white.gselect_m + .btn-group .dropdown-toggle {
		background-color: $white;
	}
	.multiselect-container {
		width: 100% !important;
		max-height: 200px;
		overflow: auto;
		top: -1px !important;
		li {
			width: 100%;
			border-top: solid 1px $white;
			a {
				outline: none;
				label {
					width: 100%;
					padding: 0.25rem 0.75rem 0.25rem 1.25rem !important;
					position: relative;
					&::before {
						content: "";
						display: inline-block;
						position: absolute;
						width: 1.1rem;
						height: 1.1rem;
						border: solid 1px $border-color;
						left: 0.75rem;
						top: 0.4rem;
						z-index: 90;
						background: $white;
					}
					input {
						margin-bottom: 0 !important;
						position: relative;
						left: -0.45rem;
					}
				}
			}
			&:hover {
				background: $bg_lightgrey;
			}
			&.active {
				background: $primary;
				color: $white;
				a label {
					&::after {
						content: "";
						position: absolute;
						left: 1.15rem;
						top: 0.55rem;
						width: 0.3rem;
						height: 0.7rem;
						border-bottom: 0.125rem solid $primary;
						border-right: 0.125rem solid $primary;
						transform: rotate(45deg);
						display: block;
						z-index: 92;
					}
				}
			}
		}
		&::-webkit-scrollbar {
			width: 0.4rem;
		}
		&::-webkit-scrollbar-track {
			background: #fafafa;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #ededed;
			border-radius: 8px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #ccc;
		}
	}
}

.multiselect-native-select + label {
	z-index: 992;
}

/* ############################ React-Phone-Input-2 ########################################*/

.react-tel-input {
	// &.label-gradient{
	// 	.filldata {
	// 	.special-label{
	// 		background: -moz-gradient(to top, $white 50%, rgba($white, 0) 0%) !important;
	// 		background: -webkit-gradient(to top, $white 50%, rgba($white, 0) 0%) !important;
	// 		background: linear-gradient(to top, $white 50%, rgba($white, 0) 0%) !important;
	// 	}
	// }
	// }

	.special-label {
		pointer-events: none;
		display: block !important;
		height: 28px !important;
		left: 48px !important;
		background: white !important;
		top: 1px !important;
		outline: none;
		font-size: .95rem!important;
		z-index: 9!important;
		@include transition(0.1s);
		line-height: 2rem;
		font-weight: 400;
	}
	.form-control {
		width: 100% !important;
		height: calc(2rem + 2px) !important;
	}
	.flag-dropdown {
		height: 28px !important;
		left: 2px !important;
		top: 1px !important;
		border: 0px !important;
		background-color: $white !important;
		z-index: 99 !important;
	}
	&.filldata {
		.special-label {
			top: -8px !important;
			height: 14px !important;
			color: $primary !important;
			line-height: 1;
			font-size: 12px!important;

			background: -moz-gradient(to top, $white 50%, rgba($white, 0) 0%) !important;
			background: -webkit-gradient(to top, $white 50%, rgba($white, 0) 0%) !important;
			background: linear-gradient(to top, $white 50%, rgba($white, 0) 0%) !important;
		}
	}
}

//   .react-tel-input .form-control{width: 100%!important; padding-left: 55px!important}
// .react-tel-input .flag-dropdown{border:none!important; border-right:solid 1px #ccc!important; border-radius: 0!important;
// z-index: 999!important; top:4px!important}
// .react-tel-input .flag-dropdown, .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus, .react-tel-input .flag-dropdown.open .selected-flag{background-color: transparent!important;}
// .form-group.input-group.mobile-number label{left:90px}
// .form-group.input-group.mobile-number.filldata label{left:8px}

/* ############################ AG grid plugin for ag-theme-alpine theme ########################################*/

.ag-theme-alpine {

	input{
		&:focus{box-shadow:0px 0px 0px #000!important;border:0px!important}
	}
.likeinput{
	background : white;
   box-shadow:0px 0px 4px #333;
}

.likeinput-danger{
	background : white;
   box-shadow:0px 0px 4px rgb(243, 53, 53);
}

	&.rounded-0 {
		.ag-root-wrapper {
			border-radius: 0px !important;
		}
		.ag-header {
			border-radius: 0rem;
		}
	}
	.react-datepicker__input-container:after {
		content: "";
		border: 0px;
		background: none;
	}

	.ag-floating-bottom{overflow: auto!important;}

	.ag-checkbox-input-wrapper.ag-checked::after {
		color: $primary !important;
		box-shadow: 0px 0px 0px #000;
	}
	.ag-checkbox-input-wrapper:focus-within {
		box-shadow: 0px 0px 0px #000 !important;
	}
	&.no-row-selection {
		.ag-row-selected {
			background-color: inherit !important;
			color: inherit !important;
		}
	}
	font-family: Roboto, sans-serif;

	.cell-span {
		background-color: white;
		z-index: 2!important;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
	  }
	
	.ag-center-cols-viewport {
		overflow-x: hidden;
	}
	.ag-text-field-input,
	.ag-input-field-input {
		color: $default-color !important;
	}
	
	.ag-cell-inline-editing {
		height: 100% !important;
		.ag-react-container {
			height: 97% !important;
			input {
				height: 100% !important;
			}
		}
	}
	.ag-root-wrapper {
		border: 1px solid #ccc !important;
		border-radius: 8px 8px 0px 0px !important;
	}
	.ag-ltr .ag-cell {
		border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
		line-height: 36px !important;
		padding-left: 4px!important;
		padding-right: 4px!important;
		
	}
	.ag-line-height{
		line-height: 26px !important;
		display: inline-block;
	}
	.ag-row-pinned{
		.ag-cell{
			padding-right: 4px!important;
			padding-left: 4px!important;
			&.border-0{
				border-width:0px!important ;
			}
		}
		
	}
	.ag-row-hover {
		background-color: #f4f4f4 !important;
		cursor: pointer;
	}
	.ag-row-selected {
		background-color: #f4f4f4 !important;
		color: $default-color !important;
		.text-danger,
		.text-primary {
			color: $primary !important;
		}
		.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
			border-color: $primary!important;
			background-color: white !important;
		}
		
	}
	
	.ag-row {
		border-color: rgba(0, 0, 0, 0.1) !important;
		border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
		border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
		min-height: 36px !important;

		&.grndetailRowColor{background: #d2435020!important;}
		&.ag-row-odd {
			background: transparent!important;
			background-color: transparent!important;
			outline: none !important;
			&.grndetailRowColor{background: #d2435020!important;}

			&.ag-row-selected{
				background: #f4f4f4 !important;
			}

			&:hover{
				background: #f4f4f4 !important;
			}
		}
	}
	.ag-header-icon {
		color: white !important;
	}
	
	.ag-cell-focus {
		border: 0px !important;
	}
	.ag-header-cell-resize::after {
		background-color: rgba(0, 0, 0, 0.1) !important;
		top: 0px !important;
		height: 100% !important;
		width: 1px !important;
	}
	.ag-header {
		background: #8b8b8b !important;
		height: 29px !important;
		max-height: 100px;
		min-height: 29px !important;
		border-radius: 0.4rem 0.4rem 0 0rem;
		color: #fff;
		border: none;
		text-transform: uppercase;
		border-right: solid 1px rgba(0, 0, 0, 0.1) !important;
		font-weight: 400 !important;
		font-size: 0.95rem !important;
		.ag-header-row,
		.ag-header-cell {
			padding: 0px !important;
			height: 29px;
			max-height: 100px;
			text-align: center!important;
			.ag-header-cell-label {
				justify-content: center;
				color: white !important;
				font-weight: 400 !important;
				font-size: 0.95rem !important;
				line-height: 0.95rem;
				padding: 0.3rem 0.5rem;
				.ag-header-cell-text {
					color: white !important;
					font-weight: 500 !important;
					font-size: 0.95rem !important;
					line-height: 0.95rem;
					font-family: Roboto, sans-serif;
					overflow: visible;
					text-overflow: clip;
					white-space: break-spaces !important;
				}
			}
		}
		.ag-header-row div:last-child {
			border: 0px !important;
		}
	}

	&.no-fixed-height {
		.ag-header {
			max-height: 100px;
			min-height: 50px !important;
			color: #fff;
			border: none;
			text-transform: uppercase;
			border-right: solid 1px rgba(0, 0, 0, 0.1) !important;
			font-weight: 400 !important;
			font-size: 0.95rem !important;
			.ag-header-row,
			.ag-header-cell {
				padding:0px 8px 0px 8px !important;
				height: 100%;
				max-height: 100%;
			}
		}
	}
}

/* ############################ React date picker ########################################*/
.react-datepicker-wrapper {
	width: 100%;

	
}

// only for month picker used in itemexpiry
.only-month-picker{
.react-datepicker__navigation--next,	.react-datepicker__navigation--previous
{
	top:8px!important;
}
}
.react-datepicker__month-container{
	.react-datepicker-year-header{
		background: #8b8888!important;
		color: white!important;
		height: 32px!important;
		line-height: 32px!important;
		

	}
.react-datepicker__month-text--keyboard-selected{
	background: $primary!important;
}

.react-datepicker__month-dropdown,.react-datepicker__year-dropdown{background:white; 
	width:auto;
	 left:auto;
	display: flex;
    flex-direction: column;
    height: 220px;
	// display: flex;
    // flex-direction: column;
    // justify-content: center;
	overflow-y: auto;
	overflow-x: hidden;
	.react-datepicker__month-option,.react-datepicker__year-option{
		width:100px!important;
		margin:0px; position:relative;
		&:hover{background:#f4f4f4!important}
}
}


}
// to reverse the calender year picker
.react-datepicker{
	&.reverseYear{
		.react-datepicker__year-dropdown{
			background: white;
    width: 100px;
    left: 0;
    display: flex;
    flex-wrap: wrap-reverse;
    height: 220px;
	overflow-y: auto;
	overflow-x: hidden;
    align-content: flex-end;
    justify-items: stretch;
    align-items: end;
	flex-direction: row;
		}
	}
}

.react-datepicker__input-container {
	&:after {
		position: absolute;
		right: 0px;
		top: 0;
		content: "\e816";
		font-family: "his-icon";
		cursor: pointer;
		pointer-events: none;
		border-left: 1px solid #ccc;
		padding: 4px 8px 0px 8px;
		height: 100%;
		background: white;
		z-index: 100;
		border-radius: 0px 4px 4px 0px;
	}
}

 

.react-datepicker {
	display: inline-flex!important;

	border: solid 1px rgba(0, 0, 0, 0.1) !important;
	padding: 4px;
	li.react-datepicker__time-list-item--selected{
		background: $primary!important;
	}
	.react-datepicker-time__header{
		height: 31px;
	line-height: 40px;
	background: #8b8888;
	color: white;
	}
	.react-datepicker__header--time {padding: 0px;	background: #8b8888;
	}
	.react-datepicker__header__dropdown {
		position: absolute;
		top: 7px;
		left: 60px;
		display: flex;
		flex-direction: row-reverse;
		.react-datepicker__month-read-view--selected-month,
		.react-datepicker__year-read-view {
			color: white;
			visibility: visible !important;
		}
		.react-datepicker__year-option--selected,
		.react-datepicker__month-option--selected {
			left: 5px !important;
		}
	}
	.react-datepicker__month-read-view--down-arrow,
	.react-datepicker__year-read-view--down-arrow {
		border-width: 0.35rem !important;
		border-top-color: white !important ;
	}
	.react-datepicker__day--keyboard-selected,
	.react-datepicker__day--selected {
		background: $primary !important;
		border-radius: 0px !important;
	}
	.react-datepicker__day-names,
	.react-datepicker__day-name {
		background: #8b8b8b;
		font-weight: 400;
		font-size: 14px;
		color: white !important;
		width: 40px;
		height: 32px;
		line-height: 32px;
		margin: 0px;
		border-right: solid 1px rgba(0, 0, 0, 0.1) !important;
	}
	.react-datepicker__navigation--previous {
		left: 12px;
		border-right-color: white;
		top: 15px;
		&:focus {
			outline: none !important;
		}
	}
	.react-datepicker__navigation--next {
		top: 15px;
		border-left-color: white;
		right: 12px;
		&:focus {
			outline: none !important;
		}
	}
	.react-datepicker__header {
		padding-top: 0px !important;
	}
	.react-datepicker__month {
		margin: 0px !important;
		border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
	}
	.react-datepicker__current-month {
		padding: 0.5rem;
		background: #8b8888;
		font-weight: 500;
		font-size: 14px;
		color: #8b8888;
		pointer-events: none;
		border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
		position: relative;
		&:before {
			width: 1px;
			height: 36px;
			position: absolute;
			content: "";
			background: rgba(0, 0, 0, 0.1);
			left: 39px;
			top: 0px;
		}
		&:after {
			width: 1px;
			height: 36px;
			position: absolute;
			content: "";
			background: rgba(0, 0, 0, 0.1);
			right: 40px;
			top: 0px;
		}
	}
	.react-datepicker__day {
		border-right: solid 1px rgba(0, 0, 0, 0.1) !important;
		margin: 0px;
		width: 40px;
		height: 32px;
		font-size: 14px;
		line-height: 32px;
	}
	.react-datepicker__week {
		border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
	}
	.react-datepicker__day:hover {
		background: #f4f4f4 !important;
		border-radius: 0px !important;
		color: $default-color !important;
	}
	.react-datepicker__day--keyboard-selected {
		border-radius: 0px !important;
	}
}

.newdatpicker {
	.activee{background: $primary!important; color:white!important}
	.headings {
		background: #8b8b8b;
		height: 34px;
		line-height: 34px;
		width: 100%;
		margin-top: 1px;
		color: #fff;
		border-right: 1px solid #b5a6a6;
	}
	.react-datepicker__day--today:hover {
		background: $primary !important;
	}
	.react-datepicker {
		padding: 0px !important;
		border: 1px solid #eee;
		border-radius: 0px !important;
	}
	.react-datepicker__header {
		border-color: #eee !important;
	}
	.react-datepicker__day-names {
		&:last-child {
			div {
				border: 0px !important;
			}
		}
		background: rgb(139, 139, 139);
		height: 30px;
		line-height: 30px;
		width: auto;
		border: 0px !important;
	}
	.react-datepicker__day-name {
		color: $white !important;
	}
	.react-datepicker__month {
		border: 0 !important;
		height: 220px;
	}
	.react-datepicker .react-datepicker__day,
	.react-datepicker__day-name {
		width: 32px !important;
		height: 34px;
		line-height: 34px;
		font-size: 12px !important;
	}
	::-webkit-scrollbar-track {
		background-color: white;
	}

	::-webkit-scrollbar {
		width: 5px;
	}

	// ::-webkit-scrollbar-thumb
	// {
	// 	background-color: #a5a4a4;
	// }
	ul {
		background: white;
		margin: 0px;
		padding: 0px;
		
		height: 218px;width: 60px;
		max-height: 218px;
		overflow-y: auto;
		border: 1px solid #eee;
		li {
			display: flex;
			flex-direction: column;
			a {
				background: white;
				padding: 0px;
				&:hover {
					background: $primary !important;
					color: white !important;
				}
			}
		}
	}
	// .react-datepicker__month-container{
	// 	&:last-child{border: 0px!important;}
	// }
	// .react-datepicker__week{
	// 	&:last-child{
	// 		div{
	// 			border:0px!important
	// 		}
	// 	}
	// 	}
	// 	.react-datepicker{ margin: 0px; box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;}
	// 	.react-datepicker__day--outside-month{display: none!important;}

	// 	.react-datepicker-popper{margin: 0px!important;}
	// 	.react-datepicker__triangle{display: none;}
}

.datepicker-no-icon {
	.react-datepicker__input-container:after {
		content: "";
		border: 0px;
		background: transparent;
	}
}

/* ############################ Custom select ########################################*/

.form_selected_control {
	.css-26l3qy-menu {
		.css-4ljt47-MenuList {
			div {
				background: white !important;
				color: #333 !important;
				font-size: 14px !important;
				&:hover {
					background: #f4f4f4 !important;
					cursor: pointer;
				}
			}
		}
	}
	.css-yk16xz-control,
	.css-1pahdxg-control {
		min-height: calc(2rem + 2px) !important;
		box-shadow: 0px 0px 0px #000;
		height: calc(2rem + 2px) !important;
	}
	.css-1wa3eu0-placeholder,
	.css-b8ldur-Input {
		top: 14px;
		color: #333;
	}
	.css-b8ldur-Input {
		padding-top: 0px !important;
		line-height: 14px !important;
	}
	.css-1pahdxg-control {
		border-color: #333 !important;
	}
	.css-tlfecz-indicatorContainer,
	.css-1gtu0rj-indicatorContainer {
		padding: 0px !important;
		color: #7b7979 !important;
		background: url(../images/ic-arrow-down.svg) no-repeat; width: 14px; height: 14px; margin-right: 7px; margin-top: -3px;
		svg{display: none;}
	}
	.css-26l3qy-menu {
		display: block !important;
	}
}

/* Material UI css */
.form-groupp {
	margin-bottom: 12px;
	.MuiChip-root{
		// height:auto !important;
	}
	.errormsg {
		position: absolute;
		top: 0px;
		left: 0px;
		font-size: 0.825rem;
		background: #e9e5e5;
		border: solid 1px #fca2a2;
		padding: 0.4rem 0.5rem 0 0.55rem;
		width: 100%;
		height: 100%;
		z-index: 99;
		border-radius: 0.25rem;
		color: #ff0000;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.filldata  label{
		color:$primary!important;
	}
	.MuiFormControl-root {
		width: 100%;
	}
	.MuiInputBase-root.Mui-disabled{
		color: #333!important;
	}
	.MuiOutlinedInput-adornedEnd {
		padding-right: 0px !important;
	}
	&.input-group {
		display: flex;
		flex-direction: row;
		.MuiFormControl-root {
			width: calc(100% - 40px) !important;
		}
		.MuiOutlinedInput-root {
			border-radius: 4px 0px 0px 4px !important;
		}
		.input-group-addon {
			border-width: 1px !important ;
			border: 1px solid #ccc;
			border-radius: 0px 4px 4px 0px;
			border-left: 0px !important;
			line-height: 20px;
			width: 40px;
		}
	}
	.MuiInputBase-input {
		padding: 7.2px 10px !important;
	}

	.MuiInputLabel-outlined {
		transform: translate(9px, 9px) scale(1) !important;
		font-size: 14px !important;
		background: white !important;
	}
	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		transform: translate(14px, -9px) scale(0.9) !important;
		color: $primary !important;
	}
	label {
		&.MuiFormLabel-root {
			color: $default !important;
		}
	}
	.MuiFormLabel-root.Mui-focused {
		color: $primary !important;
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: $border-color !important;
		border-width: 1px !important;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: $default !important;
		border-width: 1px !important;
	}
	.Mui-disabled {
		cursor: not-allowed !important;
	}
	.Mui-error {
		color: $primary !important;
		.MuiOutlinedInput-notchedOutline {
			border-color: $primary !important;
		}
	}
	.Mui-selected {
		background-color: $primary !important;
		color: $white !important;
	}
	.MuiListItem-button:hover {
		background: $bg_card !important;
		color: $default !important;
	}

	.MuiSelect-outlined.MuiSelect-outlined {
		padding: 6px 10px !important;
	}
	.MuiSelect-select:focus {
		background: white !important;
	}

	.MuiAutocomplete-inputRoot {
		padding: 0px 55px 0px 0px !important;
	}
	.MuiCheckbox-colorSecondary.Mui-checked,
	.MuiRadio-colorSecondary.Mui-checked {
		color: $primary !important;
	}
}
.new-table {
	th {
		background-color: $table_thead_bg !important;
		color: white !important;
		padding: 0.3rem 0.5rem !important;
		border-right: solid 1px rgba(0, 0, 0, 0.1);
		text-transform: uppercase;
	}
	tr:nth-child(2n) {
		background-color: #f0f4f7;
	}
	td {
		padding: 0.3rem 0.5rem !important;
		border-right: solid 1px rgba(0, 0, 0, 0.1);
	}
}

/* Sign in pages */
/* Sign in Forms */
.signinform {
	font-weight: 300;

	h1 {
		font-size: 2rem;
		font-weight: 300;
		color: $default;
		padding-bottom: 1.5rem;
		line-height: 3rem;
	}
	.btn-signin {
		padding: 9px 100px !important;
		font-size: 18px;
		border-radius: 4px !important;
		background: $primary;
		border: 0px;
		color: white;
		text-transform: uppercase;
		:focus{
			border-color: #333!important;
			box-shadow: 0px 0px 0px $primary !important;
			border: 1px solid #333 !important;
		}
	}
	.btn-clear {
		padding: 9px 100px !important;
		font-size: 18px;
		border-radius: 4px !important;
		border:1px solid $default-color!important;
		border: 0px;
		background: transparent;
		color: $default;
		text-transform: uppercase;
		:focus{
			border-color: #333!important;
			box-shadow: 0px 0px 0px $primary !important;
			border: 1px solid #333 !important;
		}
		&:hover{
			border:1px solid $primary!important;
			color:$primary!important;

		}
	}
	.icon-box {
		width: 130px;
		height: 100px;
		text-align: center;
		text-transform: uppercase;
		color: $default;
		div {
			height: 60px;
			img {
				max-width: 100%;
			}
		}
	}
}

.form-group-signin {
	margin-bottom: 12px;
	.errormsg {
		position: absolute;
		top: 0px;
		left: 0px;
		font-size: 0.825rem;
		background: #fff3f4;
		border: solid 1px #fca2a2;
		padding: 0.4rem 0.5rem 0 0.55rem;
		width: 100%;
		height: 100%;
		z-index: 99;
		border-radius: 0.25rem;
		color: #ff0000;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.MuiFormControl-root {
		width: 100%;
	}
	.MuiOutlinedInput-adornedEnd {
		padding-right: 0px !important;
	}
	&.input-group {
		display: flex;
		flex-direction: row;
		.MuiFormControl-root {
			width: calc(100% - 40px) !important;

		}
		.MuiOutlinedInput-root {
			border-radius: 4px 0px 0px 4px !important;

		}
		.input-group-addon {
			border-width: 1px !important ;
			border: 1px solid #ccc;
			border-radius: 0px 4px 4px 0px;
			background: white;
			border-left: 0px !important;
			line-height: 20px;
			width: 40px;
		}
	}
	.MuiOutlinedInput-adornedEnd {
		background: white;
	}
	.MuiInputBase-input {
		padding: 12px 10px !important;
		background: white;

	}

	.MuiInputLabel-outlined {
		transform: translate(14px, 14px) scale(1) !important;
		font-size: 16px !important;
		background: white !important;

	}
	.MuiInputLabel-outlined.MuiInputLabel-shrink {
		transform: translate(14px, -6px) scale(0.9) !important;
		color: $primary !important;
	}
	label {
		&.MuiFormLabel-root {
			color: $default !important;
			background: white !important;
		}
	}
	.MuiFormLabel-root.Mui-focused {
		color: $primary !important;
	}

	.success-form{
		.MuiOutlinedInput-notchedOutline {
			border-color: $success !important;
			border-width: 1px !important;
			box-shadow: 0px 0px 8px rgb(47, 228, 31)!important;
	
		}
	}
	.MuiOutlinedInput-notchedOutline {
		border-color: $border-color !important;
		border-width: 1px !important;
		box-shadow: 0px 0px 8px #eee;

	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: $default !important;
		border-width: 1px !important;
	}
	.Mui-disabled {
		cursor: not-allowed !important;
	}
	.Mui-error {
		color: $primary !important;
		.MuiOutlinedInput-notchedOutline {
			border-color: $primary !important;
		}
	}
	.Mui-selected {
		background-color: $primary !important;
		color: $white !important;
	}
	.MuiListItem-button:hover {
		background: $bg_card !important;
		color: $default !important;
	}

	.MuiSelect-outlined.MuiSelect-outlined {
		padding: 6px 10px !important;
	}
	.MuiSelect-select:focus {
		background: white !important;
	}

	.MuiAutocomplete-inputRoot {
		padding: 0px 55px 0px 0px !important;
	}
	.MuiCheckbox-colorSecondary.Mui-checked,
	.MuiRadio-colorSecondary.Mui-checked {
		color: $primary !important;
	}
}


.form-group-signin-new{
	position: relative;
	background: white;
	margin-bottom: 15px;
	input[type="text"],input[type="password"],select{
		height:40px;
		box-shadow: 0px 0px 8px #eee;
	}
	.addons{
		position: absolute;
		right: 10px;
		top: 10px;
		i{
			font-size: 20px;

			&.error{
				color:$danger;

			}
			&.success{
				color:#73d879;
			}
		
	}
	
	}
}
.success-new{
	box-shadow: 0px 0px 16px #73d87940!important;
	border:1px solid #73d879;
}
.errow-new{
	box-shadow: 0px 0px 16px rgba($color: $danger, $alpha: 0.3)!important;
	border:1px solid $danger;
}
/* Timeline css */
.timeline {
	position: relative;
	width: 100%;
	padding: 00px 0;
	.title {
		position: relative;
		margin-top: 00px;
		width: 100%;
		text-align: center;
	}
}

.green-timeline {
    width: 12px;
    height: 12px;
    margin-right: 12px;
    background-color: rgb(116, 167, 50);
    display: inline-block;
    border-radius: 50%;
}
.pink-timeline {
    width: 12.5px;
    height: 12.5px;
    background-color: rgb(255, 82, 232);
    display: inline-block;
    margin-right: 12px;
    border-radius: 50%;
}
.grey-timeline {
    width: 12.5px;
    height: 12.5px;
    background-color: #a3a3a3;
    display: inline-block;
    margin-right: 12px;
    border-radius: 50%;
}

.yellow-timeline{
	width: 12.5px;
    height: 12.5px;
    background-color: $warning;
    display: inline-block;
    margin-right: 12px;
    border-radius: 50%;
}
.light-green-timeline{
	width: 12.5px;
    height: 12.5px;
    background-color: #addfc3;
    display: inline-block;
    margin-right: 12px;
    border-radius: 50%;
}
.red-timeline{
	width: 12.5px;
    height: 12.5px;
    background-color: $danger;
    display: inline-block;
    margin-right: 12px;
    border-radius: 50%;
}
.text-new{
	font-size: 13px;
	display: flex;
	padding: 8px 0px;
	text-align: right;
	width: 100%;
	justify-content: end;
	align-items: end;
	margin-top: 12px;
}
p{font-size: 14px;}

.arrow-timeline-top {
	width: 12px;
	height: 12px;
	position: absolute;
	top: 70px;
	left: 50%;
	
	transform: translate(0px, -50%) rotate(180deg);
	margin-left: -6px;
	z-index: 1;
	svg {
		width: 12px;
		height: 12px;
	}
	path {
		fill: #9a9a9a;
	}
}
.arrow-reverse {
	transform: translate(0px, -50%) rotate(180deg) !important;
	margin-left: -10px;
	z-index: 1;
}

.reddot:after {
	background: $danger !important;
}
.greydot:after {
	background: #a3a3a3 !important;
}

.orangedot:after {
	background: $warning !important;
}
.greendot:after {
	background: rgb(116, 167, 50) !important;
}
.lightgreendot:after {
	background: #addfc3 !important;
}
.yellowdot:after {
	background: $warning !important;
}
.pinkdot:after {
	background: rgb(255, 82, 232) !important;
}



.timeline .timeline-container {
	position: relative;
	width: 100%;
}

.timeline .timeline-end,
.timeline .timeline-start,
.timeline .timeline-year {
	position: relative;
	width: 100%;
	text-align: center;
	z-index: 1;
}

.timeline .timeline-end p,
.timeline .timeline-start p,
.timeline .timeline-year p {
	display: inline-block;
	width: 80px;
	height: 80px;
	margin: 0;
	padding: 30px 0;
	text-align: center;
	background: #74a732;
	border-radius: 100px;
	color: #ffffff;
	font-size: 14px;
	text-transform: uppercase;
}

.timeline .timeline-year {
	margin: 30px 0;
}

.timeline .timeline-continue {
	position: relative;
	width: 100%;
	padding: 00px 0;
}
.timeline-continue div:last-child {
	.timeline-box {
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
}

.timeline .timeline-continue::after {
	position: absolute;
	content: "";
	width: 1px;
	height: calc(100% - 52px);
	top: 30px;
	left: 50%;
	margin-left: -1.5px;
	background: transparent;
	border: 1px dashed #908f8f;
}

.timeline .row.timeline-left,
.timeline .row.timeline-right .timeline-date {
	text-align: right;
}

.timeline .row.timeline-right,
.timeline .row.timeline-left .timeline-date {
	text-align: left;
}

.timeline .timeline-date {
	font-size: 14px;
	font-weight: normal;
	margin: 28px 0 0 0;
	padding: 0px 75px;
	color: #3ec378;
}

.timeline .timeline-date::after {
	content: "";
	display: block;
	position: absolute;
	width: 14px;
	height: 14px;
	top: 30px;
	background: #3ec378;
	border-radius: 15px;
	z-index: 1;
}


.timeline .lastItem::after {

	top: 35px !important;

}

.timeline .row.timeline-left .timeline-date::after {
	left: -7px;
}

.timeline .row.timeline-right .timeline-date::after {
	right: -7px;
}

.timeline .timeline-box,
.timeline .timeline-launch {
	position: relative;
	display: flex;
	margin: 16px 30px 0px 30px;
	padding: 8px;
	border-radius: 6px;
	background: transparent;
}

.timeline .timeline-launch {
	width: 100%;
	margin: 15px 0;
	padding: 0;
	border: none;
	text-align: center;
	background: transparent;
}

.timeline .timeline-box::after,
.timeline .timeline-box::before {
	content: "";
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
}

.timeline .row.timeline-left .timeline-box::after,
.timeline .row.timeline-left .timeline-box::before {
	left: 100%;
}

.timeline .row.timeline-right .timeline-box::after,
.timeline .row.timeline-right .timeline-box::before {
	right: 100%;
}

.timeline .timeline-launch .timeline-box::after,
.timeline .timeline-launch .timeline-box::before {
	left: 50%;
	margin-left: -10px;
}

.timeline .timeline-box::after {
	top: 17px;
	border-color: transparent;
	border-width: 10px;
}

.timeline .timeline-box::before {
	top: 25px;
	border-color: transparent;
	border-width: 11px;
}

.timeline .row.timeline-right .timeline-box::after {
	border-color: transparent #ffffff transparent transparent;
}

.timeline .row.timeline-right .timeline-box::before {
	border-color: transparent #fff transparent transparent;
}

.timeline .timeline-launch .timeline-box::after {
	top: -20px;
	border-color: transparent;
}

.timeline .timeline-launch .timeline-box::before {
	top: -19px;
	border-color: transparent transparent #ffffff transparent;
	border-width: 10px;
	z-index: 1;
}

.timeline .timeline-box .timeline-icon {
	position: relative;
	width: 40px;
	height: auto;
	float: left;
}

.timeline .timeline-icon i {
	font-size: 25px;
	color: #4f84c4;
}

.timeline .timeline-box .timeline-text {
	position: relative;
	width: calc(100% - 40px);
	float: left;
}

.timeline .timeline-launch .timeline-text {
	width: 100%;
}

.timeline .timeline-text h3 {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 3px;
	color: #333;
}

.timeline .timeline-text p {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
	color: #666666;
}

@media (max-width: 768px) {
	.timeline .timeline-continue::after {
		left: 40px;
	}

	.timeline .timeline-end,
	.timeline .timeline-start,
	.timeline .timeline-year,
	.timeline .row.timeline-left,
	.timeline .row.timeline-right .timeline-date,
	.timeline .row.timeline-right,
	.timeline .row.timeline-left .timeline-date,
	.timeline .timeline-launch {
		text-align: left;
	}

	.timeline .row.timeline-left .timeline-date::after,
	.timeline .row.timeline-right .timeline-date::after {
		left: 47px;
	}

	.timeline .timeline-box,
	.timeline .row.timeline-right .timeline-date,
	.timeline .row.timeline-left .timeline-date {
		margin-left: 55px;
	}

	.timeline .timeline-launch .timeline-box {
		margin-left: 0;
	}

	.timeline .row.timeline-left .timeline-box::after {
		left: -20px;
		border-color: transparent #ffffff transparent transparent;
	}

	.timeline .row.timeline-left .timeline-box::before {
		left: -22px;
		border-color: transparent #dddddd transparent transparent;
	}

	.timeline .timeline-launch .timeline-box::after,
	.timeline .timeline-launch .timeline-box::before {
		left: 30px;
		margin-left: 0;
	}
}


/* tree view no conflict */
.treeview-noconflict{
	.Mui-selected {
		 background-color: inherit !important; 
		 color: inherit !important; 
	}
}




// $height: 50px;
// $green:#2CFD30;
// $light-green:#EDFDED;
// $gray:#292929;
// $light-gray:#DEDEDE;
// .custom-toggle {
//   user-select:none;
//   width: $height * 2;
//   height: $height;
//   position: relative;
//   overflow: hidden;

//   label {
//     width: $height * 2;
//     height: $height;
//     border-radius: $height/2;
//     background-color: $light-gray;
//     cursor: pointer;
//     display: block;
//     &:active:after{
//       width:$height*1.5;
//     }
//     &:after {
//       width: $height * 0.8;
//       height: $height * 0.8;
//       background-color: $gray;
//       content: "";
//       position: absolute;
//       border-radius: $height;
//       top: 0;
//       left: 0;
//       transition: all 0.5s ;
//       margin: $height * 0.1;
//     }
//     .text{
//       display: flex;
//       justify-content: space-around;
//       align-items: center;
//       font-size: $height/2;
//       width: 100%;
//       height: 100%;
//       user-select: none;
//       .on{
//         color:$green;
//       }
//       .off{
//         color:$gray;
//       }
      
//     }
   
//   }
//   input {
//     display: none;
//     &:checked {
//       & + label {
//         background-color: $light-green;
//         &:after {
//           left: unset;
//           background-color: $green;
//           right: 0.1 * $height;
// 	 transform: translateX(0.1 * $height);
//         }
//       }
//     }
//   }
// }






/** EXAMPLE
  <label class="tgl">
    <input type="checkbox" />
    <span data-on="1" data-off="0"></span>
  </label>
**/
.tgl {
	position: relative;
	outline: 0;
	display: inline-block;
	cursor: pointer;
	user-select: none;
	margin: 0 0 5px 0;
  }
  .tgl,
  .tgl:after,
  .tgl:before,
  .tgl *,
  .tgl *:after,
  .tgl *:before,
  .tgl + .tgl-btn {
	box-sizing: border-box;
  }
  .tgl::selection,
  .tgl:after::selection,
  .tgl:before::selection,
  .tgl *::selection,
  .tgl *:after::selection,
  .tgl *:before::selection,
  .tgl + .tgl-btn::selection {
	background: none;
  }
  .tgl span {
	position: relative;
	display: block; width:100px;
	height: 1.8em;
	line-height: 1.2em;
	overflow: hidden;
	font-weight: normal;
	text-align: center;
	border-radius: 2em;
	padding: 0.2em 1em;
	border: 1px solid #fafafa;
	box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.2), 0 2px 0 rgba(255, 255, 255, 0.7);
	transition: color 0.3s ease, padding 0.3s ease-in-out, background 0.3s ease-in-out;
  }
  .tgl span:before {
	position: relative;
	display: block;
	line-height: 1.3em;
	padding: 0 0.2em;
	font-size: 1em;
  }
  .tgl span:after {
	position: absolute;
	display: block;
	content: '';
	border-radius: 2em;
	width: 1.3em;
	height: 1.3em;
	margin-left: -1.45em;
	top: 0.2em;
	background: #FFFFFF;
	transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 0.97), background 0.3s ease-in-out;
  }
  .tgl input[type="checkbox"] {
	display: none !important;
  }
  .tgl input[type="checkbox"]:not(:checked) + span {
	background: #de474e;
	color: #FFFFFF;
	padding-left: 1.6em;
	padding-right: 0.4em;
  }
  .tgl input[type="checkbox"]:not(:checked) + span:before {
	content: attr(data-off);
	color: #FFFFFF;
  }
  .tgl input[type="checkbox"]:not(:checked) + span:after {
	background: #FFFFFF;
	left: 1.6em;
  }
  .tgl input[type="checkbox"]:checked + span {
	background: #86d993;
	color: #FFFFFF;
	padding-left: 0.4em;
	padding-right: 1.6em;
  }
  .tgl input[type="checkbox"]:checked + span:before {
	content: attr(data-on);
  }
  .tgl input[type="checkbox"]:checked + span:after {
	background: #FFFFFF;
	left: 100%;
  }
  .tgl input[type="checkbox"]:disabled,
  .tgl input[type="checkbox"]:disabled + span,
  .tgl input[type="checkbox"]:read-only,
  .tgl input[type="checkbox"]:read-only + span {
	cursor: pointer;
  }
  .tgl-gray input[type="checkbox"]:not(:checked) + span {
	background: #e3e3e3;
	color: #999999;
  }
  .tgl-gray input[type="checkbox"]:not(:checked) + span:before {
	color: #999999;
  }
  .tgl-gray input[type="checkbox"]:not(:checked) + span:after {
	background: #ffffff;
  }
  .tgl-inline {
	display: inline-block !important;
	vertical-align: top;
  }
  .tgl-inline.tgl {
	font-size: 16px;
  }
  .tgl-inline.tgl span {
	min-width: 50px;
  }
  .tgl-inline.tgl span:before {
	line-height: 1.4em;
	padding-left: 0.4em;
	padding-right: 0.4em;
  }
  .tgl-inline-label {
	display: inline-block !important;
	vertical-align: top;
	line-height: 26px;
  }
 
  .simple-toggle {
	position: absolute;
	left: 0;
	right: 0;
	top: 30px;
	text-align: center;
	margin: auto;
  }
  .title,
  .subtitle {
	display: block;
	-webkit-font-smoothing: antialiased !important;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }
  .title {
	font-size: 30px;
	line-height: 40px;
	font-weight: bold;
	color: #262626;
  }
  .subtitle {
	margin-bottom: 20px;
	opacity: 0.4;
  }
  .code {
	display: inline-block;
	color: grey;
	border-radius: 5px;
	border: 1px solid #c9c9c9;
	background-color: #d6d6d6;
	margin: 10px;
	text-align: left;
	padding: 10px 10px 0 0;
	line-height: 0.6em;
	max-width: 300px;
  }
  .footer {
	margin-top: 30px;
	font-size: 14px;
	opacity: 0.4;
  }
  

  .year-scroll{ display: block; padding: 0;margin: 0;
	li{display: block;
		padding: .5px 18px .5px 18px;
		cursor: pointer;
		margin-bottom: 3px;
		text-align: center;
		border-radius: 3px;
		&:hover{background:#f0f0f0}
	&.active{background:#d24350 !important; color: #fff;}
	}
	}

//   year Month Custom Calender used in StockOpeningEntry
.custom-year-calender{
	width: 200px;
	display: flex;
	flex-direction: row-reverse;
	.react-datepicker-year-header, .react-datepicker__navigation{display: none;}
	.react-datepicker__monthPicker,.react-datepicker__input-time-container{border:0px!important; height:280px; overflow:hidden;}
	.react-datepicker__input-time-container{
		margin:2px 0px 0px 0px!important;
	.react-datepicker-time__caption{display: none;}
	.year-scroll{ display: block; padding: 0;
	li{display: block;
		padding: .5px 18px .5px 18px;
		cursor: pointer;
		margin-bottom: 3px;
		border-radius: 3px;
		&:hover{background:#f0f0f0}
	&.active{background:#d24350 !important; color: #fff;}
	}
	}

	&:hover{overflow: auto;}
}


*::-webkit-scrollbar {width: .3rem; height:.3rem}
 *::-webkit-scrollbar-track {background: #fafafa;}
 *::-webkit-scrollbar-thumb {background-color: #999; border-radius: 8px;}
 *::-webkit-scrollbar-thumb:hover {background: #5c5c5c;}
}